<template>
  <section v-if="isHandoverByDepartmentEnabled">
    <el-divider></el-divider>
    <p>
      Department specific office hours configuration, department that did not have office hours
      added will fallback to the default office hours.
      <br />This option will only work when handover by department enabled.
    </p>
    <el-dropdown @command="onChange">
      <el-button size="mini" type="primary">
        Add Department Office Hour
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          :key="`department-${department.id}`"
          :command="department"
          v-for="department in departments"
          style="text-transform: capitalize"
        >
          {{ department.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <br />
    <br />

    <el-card
      shadow="never"
      style="margin-bottom: 10px"
      v-for="(departmentDatetime, departmentDatetimeKey) in value.departmentDatetimes"
      :key="`department-datetime-${departmentDatetimeKey}`"
      :body-style="{ padding: '10px' }"
    >
      <el-row
        style="
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          margin-bottom: 0;
        "
        type="flex"
      >
        <div>
          <div style="margin-right: 20px; text-transform: capitalize; font-size: 12px">
            <strong>DEPARTMENT - &nbsp;</strong>
            {{ getDepartmentName(departmentDatetimeKey) }}
            <el-button
              @click.prevent="onDelete(departmentDatetimeKey)"
              type="text"
              icon="el-icon-delete"
              size="medium"
              circle
            />
          </div>
          <h4>Open time (SGT)</h4>
          <el-row
            v-for="(departmentOpenHourIndex, departmentRowIndex) in departmentOpenHourIndexes(
              departmentDatetimeKey
            )"
            :key="`${departmentDatetimeKey}-${departmentRowIndex}-${departmentOpenHourIndex}`"
            style="margin-bottom: 10px"
            type="flex"
          >
            <el-time-picker
              v-model="value.departmentDatetimes[departmentDatetimeKey][departmentOpenHourIndex]"
              format="h:mm A"
              value-format="h:mm a"
              placeholder="Start Time"
              arrow-control
              style="max-width: 180px"
            >
            </el-time-picker>
            <div style="padding: 0 10px; line-height: 40px">To</div>
            <el-time-picker
              v-model="
                value.departmentDatetimes[departmentDatetimeKey][departmentOpenHourIndex + 1]
              "
              format="h:mm A"
              value-format="h:mm a"
              placeholder="End Time"
              arrow-control
              style="max-width: 180px"
            >
            </el-time-picker>
            <el-checkbox-group
              v-model="safeDepartmentOpenWeekDayGroups[departmentDatetimeKey][departmentRowIndex]"
              size="small"
              style="margin-left: 10px"
            >
              <el-checkbox-button v-for="weekDay in weekDays" :label="weekDay" :key="weekDay">{{
                weekDay
              }}</el-checkbox-button>
            </el-checkbox-group>

            <el-button
              v-if="!!isOpenHourDepartmentDeletable(departmentDatetimeKey)"
              @click.prevent="
                onDeleteOpenHourDepartment(departmentDatetimeKey, departmentOpenHourIndex)
              "
              type="text"
              icon="el-icon-delete"
              size="medium"
              circle
            />
          </el-row>
          <el-button @click.prevent="onAddOpenTimeDepartment(departmentDatetimeKey)">
            Add
          </el-button>
        </div>
      </el-row>
      <el-divider></el-divider>

      <h4>Close Events (SGT)</h4>
      <div v-if="value.departmentCloseEvents && value.departmentCloseEvents[departmentDatetimeKey]">
        <el-row
          v-for="(closeEvent, index) in value.departmentCloseEvents[departmentDatetimeKey]"
          :key="index"
          style="margin-bottom: 10px; max-width: 60%"
          type="flex"
        >
          <div>
            <el-date-picker
              v-model="closeEvent.range"
              type="datetimerange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
          <el-button
            @click.prevent="onDeleteDepartmentCloseEvent(departmentDatetimeKey, index)"
            type="text"
            icon="el-icon-delete"
            size="medium"
            circle
          />
        </el-row>
      </div>
      <el-button @click.prevent="onAddDepartmentCloseEvent(departmentDatetimeKey)"> Add </el-button>
    </el-card>
  </section>
</template>

<script>
import _ from "lodash";
import moment from "moment";
const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default {
  name: "OfficeHourDepartmentDatetimes",
  props: ["setting", "value", "modules"],
  data() {
    return {
      weekDays: weekDays,
    };
  },
  methods: {
    onDelete(departmentId) {
      this.$delete(this.value.departmentDatetimes, departmentId);
      if (this.value.departmentOpenWeekDayGroups[departmentId]) {
        this.$delete(this.value.departmentOpenWeekDayGroups, departmentId);
      }
      this.$delete(this.value.departmentCloseEvents, departmentId);
    },
    onChange(department) {
      this.$set(this.value.departmentDatetimes, department.id, ["09:00 am", "06:00 pm"]);
      const defaultOpenWeekDayGroups = [...weekDays];
      this.$set(this.value.departmentOpenWeekDayGroups, department.id, [defaultOpenWeekDayGroups]);
      this.$set(this.value.departmentCloseEvents, department.id, []);
    },
    getDepartmentName(departmentId) {
      const department = this.setting.departments.find(
        (department) => department.id === departmentId
      );
      return department.name;
    },
    // remove department from settings if id changed or department removed.
    validateDepartment() {
      for (const key in this.value.departmentDatetimes) {
        const isNotValidDepartment = !this.setting.departments.find(
          (department) => department.id === key
        );
        if (isNotValidDepartment) {
          delete this.value.departmentDatetimes[key];
        }
      }

      for (const key in this.value.departmentOpenWeekDayGroups) {
        const isNotValidDepartment = !this.setting.departments.find(
          (department) => department.id === key
        );
        if (isNotValidDepartment) {
          delete this.value.departmentOpenWeekDayGroups[key];
        }
      }

      for (const key in this.value.departmentCloseEvents) {
        const isNotValidDepartment = !this.setting.departments.find(
          (department) => department.id === key
        );
        if (isNotValidDepartment) {
          delete this.value.departmentCloseEvents[key];
        }
      }
    },
    /**
     * Example:
     * (0, 1) is the first range
     * (2, 3) is the second range
     *
     * @return [0, 2, 4, 6, ...]
     */
    departmentOpenHourIndexes(departmentDatetimeKey) {
      const indexes = [];
      for (let i = 0; i < this.value.departmentDatetimes[departmentDatetimeKey].length; i += 2) {
        if (i + 1 < this.value.departmentDatetimes[departmentDatetimeKey].length) {
          indexes.push(i);
        }
      }

      return indexes;
    },
    onAddOpenTimeDepartment(departmentKey) {
      this.value.departmentDatetimes[departmentKey].push("09:00 am");
      this.value.departmentDatetimes[departmentKey].push("06:00 pm");

      const defaultOpenWeekDayGroups = [...weekDays];
      this.value.departmentOpenWeekDayGroups[departmentKey].push(defaultOpenWeekDayGroups);
    },
    onDeleteOpenHourDepartment(departmentKey, departmentOpenHourIndex) {
      this.value.departmentDatetimes[departmentKey].splice(departmentOpenHourIndex, 2);
      this.value.departmentOpenWeekDayGroups[departmentKey].splice(departmentOpenHourIndex, 1);
    },
    isOpenHourDepartmentDeletable(departmentKey) {
      return this.value.departmentDatetimes[departmentKey].length > 2; // 0- startTime, 1- endTime
    },
    getDepartmentCloseEvents(departmentKey) {
      if (!this.value.departmentCloseEvents || !this.value.departmentCloseEvents[departmentKey]) {
        return [];
      }

      return this.value.departmentCloseEvents[departmentKey];
    },
    onAddDepartmentCloseEvent(departmentKey) {
      if (!this.value.departmentCloseEvents[departmentKey]) {
        this.value.departmentCloseEvents[departmentKey] = [];
      }
      // Default range is at 12:00 today
      const startTime = new Date();
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      const endTime = new Date(startTime);
      const strStartTime = moment(startTime).format("yyyy-MM-DD HH:mm:ss");
      const strEndTime = moment(endTime).format("yyyy-MM-DD HH:mm:ss");
      const closeEvent = {
        range: [strStartTime, strEndTime],
      };

      this.$set(
        this.value.departmentCloseEvents[departmentKey],
        this.value.departmentCloseEvents[departmentKey].length,
        closeEvent
      );
    },
    onDeleteDepartmentCloseEvent(departmentKey, index) {
      this.value.departmentCloseEvents[departmentKey].splice(index, 1);
    },
    getDefaultDepartmentOpenWeekDayGroups() {
      const departmentOpenWeekDayGroups = {};
      for (const departmentKey in this.departmentDatetimes) {
        departmentOpenWeekDayGroups[departmentKey] = [];
        for (let i = 0; i < this.value.departmentDatetimes[departmentKey].length; i += 2) {
          if (i + 1 < this.value.departmentDatetimes[departmentKey].length) {
            const defaultOpenWeekDayGroups = [...weekDays];
            departmentOpenWeekDayGroups[departmentKey].push(defaultOpenWeekDayGroups);
          }
        }
      }

      return departmentOpenWeekDayGroups;
    },
  },
  computed: {
    /**
     * @description Check if handover by department enabled
     * @return {boolean}
     */
    isHandoverByDepartmentEnabled() {
      return _.get(this.modules, "handover.handoverRouting.byDepartment", false);
    },

    /**
     * @description Departments list
     */
    departments() {
      const added = _.keys(this.value.departmentDatetimes);
      return this.setting.departments.filter((department) => !_.includes(added, department.id));
    },
    safeDepartmentOpenWeekDayGroups() {
      if (!this.value.departmentOpenWeekDayGroups) {
        const departmentOpenWeekDayGroups = this.getDefaultDepartmentOpenWeekDayGroups();
        this.$set(this.value, "departmentOpenWeekDayGroups", departmentOpenWeekDayGroups);
      }

      return this.value.departmentOpenWeekDayGroups;
    },
  },
  beforeMount() {
    if (!this.value.departmentDatetimes) {
      this.$set(this.value, "departmentDatetimes", this.setting.default);
    } else {
      this.validateDepartment();
    }
    if (!this.value.departmentOpenWeekDayGroups) {
      const departmentOpenWeekDayGroups = this.getDefaultDepartmentOpenWeekDayGroups();

      this.$set(this.value, "departmentOpenWeekDayGroups", departmentOpenWeekDayGroups);
    }

    if (!this.value.departmentCloseEvents) {
      this.$set(this.value, "departmentCloseEvents", {});
    }
  },
};
</script>
