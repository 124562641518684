import { ATTRIBUTE_TYPE } from "@/helperMethods/outreach/attribute";

export type OutreachState = {
  contacts: {
    data: Partial<OutReachContact>[];
    count: number;
  };
  tags: {
    data: Partial<OutReachTag>[];
  };
  stages: {
    data: Partial<OutReachTag>[];
  };
  isLoading: boolean;
  filterBoard: [];
  sortBoard: {};
  contactAttributes: ContactAttribute[];
};

export class OutReachContact {
  id!: string;
  name!: string;
  primaryContact!: string;
  channel!: string;
  tags!: OutReachTag[];
  stage!: OutReachTag;
  country!: string;
  lastSeen!: string;
  ID!: string;
  isDuplicate!: boolean;
  contactattributes!: ContactAttribute[];
}

export interface OutReachTag {
  id: string;
  name: string;
  type: OUT_REACH_TAG_TYPE;
}

export interface ContactAttribute {
  id: number;
  name: string;
  label: string;
  type: ATTRIBUTE_TYPE;
  value: string[];
  isDeleted?: boolean;
}

export enum OUT_REACH_TAG_TYPE {
  TAG = "TAG",
  STAGE = "STAGE",
}

export interface OutreachAutomation {
  id: string;
  bot: string;
  name: string;
  context?: string;
  enabled: boolean;
  channels: AutomationChannel[];
  messages: AutomationMessage[];
  contactTags: number[];
  contactStages: number[];
  contactList: number[];
  contactAttributes: AutomationContactAttributes[];
  contactOperations: string[];
  stateVariables: AutomationStateVariables[];
  scheduleType: AUTOMATION_SCHEDULE_TYPE;
  scheduleCron: string[];
  startDate?: Date;
  endDate?: Date;
  sentTime?: Date;
  updates: AutomationUpdate[];
  isDeleted?: boolean;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export type AutomationMessage = {
  contentType: AUTOMATION_MESSAGE_CONTENT_TYPE;
  contentId: string;
  contentName?: string;
  customMessage?: string;
  contentParameters?: Record<string, any>;
};

export type AutomationUpdate = {
  property: string;
  updateTo: number[];
  updateItem?: Record<string, any>;
  method: AUTOMATION_UPDATE_METHOD;
  failed?: boolean;
};

export type AutomationChannel = {
  name: AUTOMATION_CHANNEL_NAME;
  settings: Record<string, any>;
};

export type AutomationContactAttributes = {
  attribute: string;
  operator: AUTOMATION_ATTRIBUTE_OPERATOR; 
  value: string; 
};

export type AutomationStateVariables = {
  variable: string;
  operator: AUTOMATION_ATTRIBUTE_OPERATOR; 
  value: string; 
};

export enum AUTOMATION_SCHEDULE_TYPE {
  NOT_SET = "NOT_SET",
  SEND_NOW = "SEND_NOW",
  SCHEDULED = "SCHEDULED",
  RECURRING = "RECURRING",
}

export enum AUTOMATION_UPDATE_METHOD {
  OVERRIDE = "OVERRIDE",
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export enum AUTOMATION_MESSAGE_CONTENT_TYPE {
  MESSAGE = "MESSAGE",
  CONTENT_NODE = "CONTENT_NODE",
  WA_MESSAGE_TEMPLATE = "WA_MESSAGE_TEMPLATE",
}

export enum AUTOMATION_MESSAGE_PARAMETER_TYPE {
  TEXT = "TEXT",
  VARIABLE = "VARIABLE",
}

export enum AUTOMATION_CHANNEL_NAME {
  WHATSAPP = "WHATSAPP",
  EMAIL = "EMAIL",
  API_INTEGRATION = "API_INTEGRATION",
}

export enum AUTOMATION_OPERATION {
  AND = "AND",
  OR = "OR",
  TAGS = "TAGS",
  LIST = "LIST",
  STAGES = "STAGES",
  ATTRIBUTES = "ATTRIBUTES",
}

export enum AUTOMATION_ATTRIBUTE_OPERATOR {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  LESS_THAN = "LESS_THAN",
  GREATER_THAN = "GREATER_THAN",
}
