<template>
  <swiper
    ref="carousel-swiper"
    :options="swiperOptions"
    style="padding: 0 15px 15px 0; margin-right: 15px"
  >
    <swiper-slide
      v-for="(item, index) in content"
      :key="index"
      style="max-width: 45%; height: auto"
    >
      <el-card
        style="height: 100%"
        :body-style="{
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }"
        shadow="never"
      >
        <div v-if="(item.image || item.image_url) && (item.text || item.title)">
          <div
            v-if="getCardImage(item)"
            class="image"
            style="background-position: center; background-size: cover; height: 160px"
            :style="`background-image:url('${getCardImage(item)}')`"
          />
        </div>

        <img v-else-if="item.image" style="height: 100%; width: 100%" :src="item.image" />

        <div style="display: flex; flex-direction: column; height: 100%">
          <div
            v-if="item.text || item.title || item.subtext"
            style="padding: 8px; flex-grow: 1; text-align: left; max-height: 130px"
          >
            <div style="line-height: 18px">
              <span style="font-weight: 600; font-size: 18px" v-text="getText(item)" />
            </div>
            <div style="margin: 4px 0; line-height: 16px">
              <small class="subtext">{{ item.subtext }}</small>
            </div>
          </div>

          <el-row :gutter="0" style="flex-direction: column; padding: 0 10px; margin-bottom: 0">
            <el-button
              v-for="(button, buttonIndex) in item.buttons"
              v-text="getText(button)"
              size="small"
              target="_blank"
              style="width: 100%; margin: 0 0 10px 0"
              :key="buttonIndex"
              :plain="true"
              type="primary"
            />
          </el-row>
        </div>
      </el-card>
    </swiper-slide>
  </swiper>
</template>

<script>
import _ from "lodash";
import {
  Swiper as SwiperClass,
  // Pagination,
  // Mousewheel,
  // Autoplay,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  props: ["content", "isPreview"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
        },
        direction: "horizontal",
      },
    };
  },
  methods: {
    getText(button) {
      const buttonText = _.get(button, "text", button.title); // Default is 'text', else facebook is 'title'
      return buttonText;
    },
    getCardImage(content) {
      const imageUrl = _.get(content, "image", content.image_url); // Default is 'image', else facebook is 'image_url'
      return imageUrl;
    },
  },
};
</script>

<style scoped>
@import "~swiper/swiper-bundle.css";
>>> .swiper-wrapper {
  height: auto;
  align-items: stretch;
}
</style>
