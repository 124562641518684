<template>
  <div>
    <el-form-item>
      <el-autocomplete
        v-model="formName"
        class="autocomplete-list autocomplete-list-target"
        style="margin-top: 0"
        placeholder=""
        @select="updateData"
        :fetch-suggestions="getFormSuggestions"
      >
        <div slot="prepend" style="width: 40px">Name</div>
      </el-autocomplete>
    </el-form-item>

    <el-form-item>
      <el-input :value="formId" disabled>
        <template slot="prepend">ID</template>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-input v-model="value.data" placeholder="iframe" disabled>
        <template slot="prepend">Data</template>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
import _ from "lodash";
import { rest } from "@/store/api";

export default {
  name: "EventNodeTypeForm",
  props: ["value"],
  data: () => {
    return {
      formName: "",
    };
  },
  computed: {
    formId() {
      let name = this.formName;
      if (!name) return "";

      return _.chain(this.$store.state.forms).find({ name }).get("id").value();
    },
  },
  methods: {
    updateData() {
      this.value.data = "event=embedded&id=" + this.formId;
    },
    /**
     * @description Search available api mappings
     * @param {string} value
     * @return {[]}
     */
    searchFormName(searchTerm) {
      if (searchTerm?.length == 0) {
        return this.$store.state.search.form?.list;
      } else {
        return this.$store.state.search.form?.search(searchTerm);
      }
    },

    /**
     * @description el-autocomplete on fetchSuggestions event handler
     * @param {string} searchTerm
     * @param {Function} cb
     * @return {void}
     */
    getFormSuggestions(searchTerm, cb) {
      cb(this.searchFormName(searchTerm));
    },
  },
  mounted() {
    let id = this.value.data.split("&id=").pop();
    let form = _.find(this.$store.state.forms, { id });
    this.formName = form?.name || "";
  },
  async created() {
    if (this.$store.state.search.form?.list.length === 0) {
      const { data } = await rest("get", "forms/v1");
      this.$store.commit("SET_FORMS", data);
    }
  },
};
</script>
