import _ from "lodash";
import { formatCamelCaseToStringNormal, IOption } from ".";

export enum ATTRIBUTE_TYPE {
  TEXT_BOX = "textBox",
  DROPDOWN = "dropdown",
  CHECKBOX = "checkbox",
}
export enum OPTION_CHECKBOX {
  NONE = "None",
  TRUE = "True",
  FALSE = "False",
}

export const attributeTypeOption = (): IOption<ATTRIBUTE_TYPE>[] =>
  Object.values(ATTRIBUTE_TYPE).map((value) => ({
    label: formatCamelCaseToStringNormal(value),
    value,
  }));

export const convertNameToLabelAttribute = (name?: string): string => {
  if (!name) return "";
  return name
    .split("_")
    .map((text) => _.upperFirst(text))
    .join(" ");
};

export const optionCheckbox = (isCreateAttribute = false): IOption<OPTION_CHECKBOX>[] => {
  let valueOption = Object.values(OPTION_CHECKBOX);
  if (!isCreateAttribute) {
    valueOption = valueOption.filter((value) => value !== OPTION_CHECKBOX.NONE);
  }
  return valueOption.map((value) => ({
    label: value,
    value,
  }));
};

export const optionDropdownAttribute = (options: string[] = []): IOption<string>[] => {
  return options.map((label) => ({
    label,
    value: label,
  }));
};
