<template>
  <div>
    <div
      class="news-icon"
      :class="isOpenNews ? 'opened-news' : ''"
      @click="handleOpen()"
      @mouseenter="mouseHover(true)"
      @mouseleave="mouseHover(false)"
    >
      <BellNotification :isHover="isHoverBell" :isOpened="isOpenNews" />
      <p>What's New</p>
    </div>

    <div class="news-feature" v-if="isOpenNews">
      <div class="news-feature-overlay" @click="clickOverlay()"></div>
      <div class="news-feature-container">
        <div class="news-feature-container-title">Product Updates</div>
        <el-tabs type="card" class="demo-tabs">
          <el-tab-pane
            v-for="(dataObject, index) in dataJson"
            :key="index"
            :label="`${dataObject.title}`"
            :active="index === 0 ? true : false"
          >
            <div class="news-feature-container-content">
              <div
                class="news-feature-container-content-item"
                v-for="(data, i) in dataObject.data"
                :key="i"
              >
                <div class="news-feature-container-content-item-tags">
                  <div
                    v-for="(tag, index) in data.tags"
                    :key="index"
                    :style="backgroundTag(tag, index)"
                  >
                    {{ tag }}
                  </div>
                </div>
                <p class="news-feature-container-content-item-title">
                  {{ data.title }}
                </p>
                <p class="news-feature-container-content-item-content">
                  {{ limitCharacters(data.summary) }}
                </p>

                <div class="news-feature-container-content-item-link">
                  <a :href="data.link" target="_blank" rel="noopener noreferrer"
                    >Read more <ExportIcon
                  /></a>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ExportIcon from "./ExportIcon.vue";
import BellNotification from "./BellNotification.vue";
import DataFeatures from "./DataFeatures.JSON";
import { getTagSlug } from "@/helperMethods/announcement/index";
export default {
  components: { ExportIcon, BellNotification },
  data() {
    const colorArray = ["#D6F5FF", "#D1DEF7"];
    const colorObject = {
      "new-feature": "#BFF5AB",
      enhancement: "#fcebea",
    };
    return {
      isOpenNews: false,
      isHoverBell: false,
      dataJson: DataFeatures,
      colorObject,
      colorArray,
    };
  },
  created() {
    window.addEventListener("keypress", (e) => {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    });
    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    });
  },
  methods: {
    mouseHover(isHover) {
      this.isHoverBell = isHover;
    },
    clickOverlay() {
      this.handleClose();
    },
    handleClose() {
      this.isOpenNews = false;
    },
    handleOpen() {
      this.isOpenNews = true;
    },
    closeDialog(isOpen) {
      this.$emit("handleDialog", isOpen);
    },
    backgroundTag(tag, index) {
      const tagSlug = getTagSlug(tag);
      return {
        background: this.colorObject[tagSlug] || this.colorArray[index % 2],
      };
    },
    limitCharacters(text) {
      if (text.length > 250) return text.substring(0, 250) + "...";
      return text;
    },
  },
};
</script>

<style lang="scss" scope>
aside.el-aside {
  z-index: 4;
}
.news-icon {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background: white;
  color: #1d57d8;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  &:hover {
    background: #1d57d8;
    color: white;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }
  &.opened-news {
    background: #1d57d8;
    color: white;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }
  > i {
    font-size: 20px;
  }
  > p {
    margin: 0;
    font-family: "Avenir", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 14px;
  }
  cursor: pointer;
}
.news-feature {
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 9999999999999999;
  background: rgba(4, 4, 4, 0.4);
  overflow-y: scroll;
  &-overlay {
    width: 100%;
    height: 100%;
    // background: rgba(4, 4, 4, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }
  &-container {
    position: absolute;
    width: 462px;
    height: 680px;
    background: white;
    border-radius: 10px;
    top: 54px;
    left: 120px;
    z-index: 999999;
    > div {
      width: 100%;
    }
    &-title {
      text-align: center;
      background: #1d57d8;
      height: 47px;
      color: white;
      font-family: "Avenir", sans-serif;
      font-weight: 900;
      font-size: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-content {
      height: 550px;
      overflow-y: scroll;
      &-item {
        &:last-child {
          border: none;
        }
        border-bottom: 0.5px solid #787878;
        padding: 9px 15px 9px 20px;
        &-title {
          font-weight: bold;
          font-family: "Avenir", sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 14px;
          line-height: 19px;
          margin: 11px 0;
        }
        &-content {
          margin: 11px 0 0 0;
          font-family: "Avenir", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.6);
        }
        &-link {
          font-family: "Avenir", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #1d57d8;
          text-align: right;
        }
        &-tags {
          display: flex;
          flex-wrap: wrap;
          div {
            font-family: "Avenir", sans-serif;
            font-size: 11px;
            margin-right: 10px;
            border-radius: 20px;
            color: black;
            padding: 4px 15px;
            border-radius: 20px;
            margin-right: 14px;
          }
        }
      }
    }
  }
}
</style>
