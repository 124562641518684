<template>
  <section>
    <h4>Open time (SGT)</h4>
    <el-row
      v-for="(openHourIndex, rowIndex) in openHourIndexes"
      :key="`${rowIndex}-${openHourIndex}`"
      style="margin-bottom: 10px; align-items: center"
      type="flex"
    >
      <el-time-picker
        v-model="value.datetimes[openHourIndex]"
        format="h:mm A"
        value-format="h:mm a"
        placeholder="Start Time"
        arrow-control
        style="max-width: 180px"
      >
      </el-time-picker>
      <div style="padding: 0 10px">To</div>
      <el-time-picker
        v-model="value.datetimes[openHourIndex + 1]"
        format="h:mm A"
        value-format="h:mm a"
        placeholder="End Time"
        arrow-control
        style="max-width: 180px"
      >
      </el-time-picker>
      <el-checkbox-group
        v-model="safeOpenWeekdayGroups[rowIndex]"
        size="small"
        style="margin-left: 10px"
      >
        <el-checkbox-button v-for="weekDay in weekDays" :label="weekDay" :key="weekDay">{{
          weekDay
        }}</el-checkbox-button>
      </el-checkbox-group>
      <el-button
        v-if="isOpenHourDeletable"
        @click.prevent="onDeleteOpenHour(openHourIndex)"
        type="text"
        icon="el-icon-delete"
        size="medium"
        circle
      />
    </el-row>
    <el-button @click.prevent="onAddOpenTime"> Add </el-button>

    <el-divider></el-divider>

    <h4>Close Events (SGT)</h4>
    <el-row
      v-for="(closeEvent, index) in value.closeEvents"
      :key="index"
      style="margin-bottom: 10px; max-width: 60%"
      type="flex"
    >
      <div>
        <el-date-picker
          v-model="value.closeEvents[index].range"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <el-button
        @click.prevent="onDeleteCloseEvent(index)"
        type="text"
        icon="el-icon-delete"
        size="medium"
        circle
      />
    </el-row>
    <el-button @click.prevent="onAddCloseEvent"> Add </el-button>
  </section>
</template>

<script>
import _ from "lodash";
import moment from "moment";

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default {
  name: "OfficeHourDatetimes",
  props: ["setting", "value", "modules"],
  data() {
    return {
      weekDays: weekDays,
    };
  },
  methods: {
    onAddOpenTime() {
      this.value.datetimes.push("07:00 am");
      this.value.datetimes.push("06:00 pm");

      const defaultoOpenWeekDayGroups = [...weekDays];
      this.value.openWeekDayGroups.push(defaultoOpenWeekDayGroups);
    },
    onDeleteOpenHour(i) {
      this.value.datetimes.splice(i, 2);
    },
    onAddCloseEvent() {
      if (!this.value.closeEvents) {
        this.value.closeEvents = [];
      }
      // Default range is at 12:00 today
      const startTime = new Date();
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      const endTime = new Date(startTime);
      const strStartTime = moment(startTime).format("yyyy-MM-DD HH:mm:ss");
      const strEndTime = moment(endTime).format("yyyy-MM-DD HH:mm:ss");
      const closeEvent = {
        range: [strStartTime, strEndTime],
      };

      this.$set(this.value.closeEvents, this.value.closeEvents.length, closeEvent);
    },
    onDeleteCloseEvent(i) {
      this.value.closeEvents.splice(i, 1);
    },
  },
  computed: {
    /**
     * Example:
     * (0, 1) is the first range
     * (2, 3) is the second range
     *
     * @return [0, 2, 4, 6, ...]
     */
    openHourIndexes() {
      const indexes = [];
      for (let i = 0; i < this.value.datetimes.length; i += 2) {
        if (i + 1 < this.value.datetimes.length) {
          indexes.push(i);
        }
      }

      return indexes;
    },
    isOpenHourDeletable() {
      return this.value.datetimes.length > 2; // 0- startTime, 1- endTime
    },
    // Use model from computed value in case openWeekDayGroups is not initialized.
    safeOpenWeekdayGroups() {
      if (!this.value.openWeekDayGroups) {
        // There must be one open timeslot available
        const defaultoOpenWeekDayGroups = [...weekDays];
        this.$set(this.value, "openWeekDayGroups", [defaultoOpenWeekDayGroups]);
      }

      return this.value.openWeekDayGroups;
    },
  },
  beforeMount() {
    if (!this.value.closeEvents) {
      this.$set(this.value, "closeEvents", []);
    }
    if (!this.value.openWeekDayGroups) {
      // There must be one open timeslot available
      const defaultoOpenWeekDayGroups = [...weekDays];
      this.$set(this.value, "openWeekDayGroups", [defaultoOpenWeekDayGroups]);
    }
  },
};
</script>
