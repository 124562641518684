import {
  InteractionType,
  LivechatFormData,
  LivechatSessionType,
  LivechatStateVariables,
} from "@/store/modules/livechat/types";
import _ from "lodash";
import { simplifyMessage } from "@/helperMethods/livechat/simplifyMessage";
import moment from "moment";

export function prepareZipText(
  chat: LivechatSessionType,
  keysToBeExcluded: string[],
  isLiveChat: boolean
): string {
  let text = "---- User Info ----\n";
  // Add single quotes to avoid formulas being evaluated

  _.map(chat, (value, key) => {
    const toBeExclued = keysToBeExcluded.includes(key);
    if (toBeExclued) return;
    const formattedValue = getValueStringfied(value);
    text += `${key}:, ${formattedValue}'`;
    text += "\n";
  });

  text += "\n\n\n";

  if (isLiveChat) {
    text += "---- Livechat Session data ----\n";

    // Add single quotes to avoid formulas being evaluated
    const livechatData = _.get(chat, "livechat_data", chat);
    const multipleAgents = _.get(livechatData, "agents.length", 1) > 1;

    _.map(livechatData, (value, key) => {
      if (key === "interactions") return;
      const formattedValue = getValueStringfied(value);
      text += `${key}:, ${formattedValue}'`;
      text += "\n";
    });

    text += "\n\n\n";

    text += "---- Livechat Session Interactions ----\n";

    _.map(livechatData.interactions, (value) => {
      const message = simplifyMessage(value);
      if (message) {
        const timestampString = moment(value.date_created).format("DD-MM-YYYY HH:mm:ss");

        let exportedText = "";
        if (message.url) {
          exportedText = `"Media: ${message.url.replace(/\n/, "")}"`;
        } else if (message.text) {
          exportedText = `"${message.text.replace(/\n/, "")}"`;
        }
        if (exportedText) {
          const isUser = value.type === "message" || value.type === "postback";
          if (isUser) {
            text += `${timestampString}, User:', ,${exportedText}`;
          } else {
            const botOrAgent = extractBotOrAgentLabel(multipleAgents, value);
            text += `${timestampString}, , ${botOrAgent}:',${exportedText}`;
          }

          text += "\n";
        }
      }
    });
  } else {
    text += "---- Interactions ----\n";
    const session_history = JSON.parse((chat as any).session_history);
    _.map(session_history, (value) => {
      const message = simplifyMessage(value);
      if (message) {
        const timestampString = moment(value.date_created).format("DD-MM-YYYY HH:mm:ss");

        let exportedText = "";
        if (message.url) {
          exportedText = `"Media: ${message.url.replace(/\n/, "")}"`;
        } else if (message.text) {
          exportedText = `"${message.text.replace(/\n/, "")}"`;
        }
        if (exportedText) {
          const isUser = value.type === "message" || value.type === "postback";
          if (isUser) {
            text += `${timestampString}, User:', ,${exportedText}`;
          } else {
            text += `${timestampString}, , ${value.bot ? "Bot" : "Agent"}:',${exportedText}`;
          }

          text += "\n";
        }
      }
    });
  }

  return text;
}

export function prepareExcelText(
  chat: LivechatSessionType,
  keysToBeExcluded: string[]
): string[][] {
  const ws_data = [["---- User Info ----"]];
  // Add single quotes to avoid formulas being evaluated
  _.map(chat, (value, key: string) => {
    const toBeExclued = keysToBeExcluded.includes(key);
    if (toBeExclued) return;

    if (key === "context_choices" && typeof value === "string") {
      const arr = [`${key}:`];
      const valueArray = value.split(",");
      valueArray.forEach((val) => {
        arr.push(`${val}'`);
      });
      ws_data.push(arr);
      return;
    }

    const updatedValue = getValueStringfied(value);
    ws_data.push([`${key}:`, updatedValue]);
  });

  ws_data.push([], [], []);
  ws_data.push(["---- Livechat Session data ----"]);

  // Add single quotes to avoid formulas being evaluated
  const livechatData = _.get(chat, "livechat_data", chat);
  const multipleAgents = _.get(livechatData, "agents.length", 1) > 1;

  _.map(livechatData, (value, key) => {
    if (key === "interactions") return;
    const valueStringifed = getValueStringfied(value);
    ws_data.push([`${key}:`, valueStringifed]);
  });

  ws_data.push([], [], []);
  ws_data.push(["---- Livechat session interactions ----"]);
  // Add single quotes to avoid formulas being evaluated

  const sortedLivechatInteractions = livechatData.interactions.sort(
    (interaction1: any, interaction2: any) => {
      if (interaction1.date_created > interaction2.date_created) {
        return 1;
      } else if (interaction1.date_created < interaction2.date_created) {
        return -1;
      }
      return 0;
    }
  );
  const uniqInteractions = _.uniqBy(sortedLivechatInteractions, "RowKey");
  _.map(uniqInteractions, (value: any) => {
    const msg = simplifyMessage(value);
    if (msg) {
      const timestampString = moment(value.date_created).format("DD-MM-YYYY HH:mm:ss");

      const msgImage = {
        is: !_.chain(msg).get("images").isEmpty().value(),
        text: "[Image Content]",
      };
      const msgFile = {
        is: !_.chain(msg).get("files").isEmpty().value(),
        text: "[File Content]",
      };

      const isUser = value.type === "message" || value.type === "postback";
      if (isUser) {
        let text = msg.text || msg.postbackText || msg.data;
        if (_.isEmpty(text)) {
          text = "";
          if (msgImage.is) ({ text } = msgImage);
          if (msgFile.is) ({ text } = msgFile);
        }
        ws_data.push([timestampString, "User: " + text]);
      } else {
        let text = _.get(msg, "text", "");
        if (_.isEmpty(text)) {
          if (msgImage.is) ({ text } = msgImage);
          if (msgFile.is) ({ text } = msgFile);
        }
        const botOrAgent = extractBotOrAgentLabel(multipleAgents, value);
        ws_data.push([timestampString, "", `${botOrAgent}: ` + text]);
      }
    }
  });

  return ws_data;
}

export function removeInitialInteraction(interactions: { [key: string]: any }[]) {
  if (interactions.length === 0) {
    return interactions;
  }
  const firstInteraction = _.first(interactions);
  const content = _.get(firstInteraction, "data.content[0]");
  if (!content) return interactions;

  const isAutomaticStartConversationInteraction =
    content.text === undefined && content.event === "goto" && content.data === "conversation_start";
  if (!isAutomaticStartConversationInteraction) return interactions;

  return _.slice(interactions, 1);
}
function getValueStringfied(
  value:
    | string
    | number
    | boolean
    | Date
    | string[]
    | InteractionType[]
    | LivechatStateVariables
    | undefined
    | LivechatFormData
): string {
  let result = `${value}`;

  if (typeof value === "string") {
    result = value;
  }
  if (typeof value === "number") {
    result = String(value);
  }
  if (typeof value === "boolean") {
    result = String(value).toLowerCase();
  }
  if (typeof value === "object") {
    result = JSON.stringify(value).toLowerCase();
  }

  return result;
}
function extractBotOrAgentLabel(multipleAgents: boolean, interactionObj) {
  const agentEmail = _.get(interactionObj, "meta.email", "");
  const agentLabel = multipleAgents && agentEmail ? `Agent (${agentEmail})` : "Agent";
  const botOrAgent = interactionObj.type === "agent" ? agentLabel : "Bot";
  return botOrAgent;
}
