import { ICard } from "@/helperMethods/type";

export interface UserDetailsPayload {
  name: string;
  alias: string;
  mobileNo: string;
  email: string;
  comments: string;
}

export type ConnectedAgent = {
  display_name?: string;
  email?: string;
  id?: string;
  name?: string;
  status?: "ONLINE" | "OFFLINE" | any;
};

export type LivechatState = {
  connectedAgents: ConnectedAgent[];
  socketConnection: string;
  agentStatus: AgentStatusType;
  activeTabName: string;
  selectedChatId: string;
  selectedChat: (LivechatSessionType & { formData: {} }) | null;
  sendEmailLoading: boolean;

  livechats?: LivechatSessionType[];
  queueChatObject: { [chatId: string]: LivechatSessionType };
  queueChatCount: number;
  unfilteredResolvedChatsArray: Array<LivechatSessionType>;
  unfilteredAbandonedChatsArray: Array<LivechatSessionType>;
  resolvedChatsArray: Array<LivechatSessionType>;
  resolvedChatCount: number;
  abandonedChatsArray: Array<LivechatSessionType>;
  abandonedChatCount: number;
  monitorChatsArray: Array<LivechatSessionType>;

  defaultFilters: Filter;
  queueChatFilters: any;
  resolvedChatFilters: Filter;
  abandonedChatFilters: Filter;
  monitorChatFilters: Filter;

  fetchQueueChatLoading: boolean;
  fetchResolvedChatLoading: boolean;
  fetchAbandonedChatLoading: boolean;
  fetchMonitorChatLoading: boolean;

  fieldData: { [key: string]: string[] };

  selectedMonitorChatPartitionKey: string;
  selectedMonitorChatRowKey: string;
  typingIndicatorUserIds: { [key: string]: boolean };
};

interface Filter {
  sessionId: string;
  chatSources: [];
  contextHistory: string;
  date: Date;
  endDate?: Date;
  startDate?: Date;
  tags: string[];
  limit: number;
  offset: number;
  searchTerm: string;
  messageLimit: number;
  involvedMe?: boolean;
  departments?: string[];
  agentIs?: string;
  agentIsNot?: string;
  includeInteractions?: boolean;
}

export interface MonitorChat {
  RowKey: string;
  PartitionKey: string;
  last_modified: string;
  date_created: string;
  channel: string;
  private_variables: string;
  entities: any;
  [key: string]: any;
  livechat_data: {
    RowKey: string;
    PartitionKey: string; // chat PartitionKey
    startQueueTime: string;
    endResolveTime: null;
    agents: [];
    tags: [];
    resolved: false;
    interactions: InteractionType[];
  };
}

export interface LivechatSessionType {
  RowKey: string;
  PartitionKey: string;
  date_created: Date;
  last_modified?: Date;

  user_id: string;
  sessionId: string; // FIXME: this is from agent's perceptive OR user's?
  startQueueTime: Date;
  startResolveTime: Date;

  agents: string[];
  endResolveTime?: string;
  endedBy?: string;
  tags?: string[];

  department?: string;

  interactions: InteractionType[];

  // FIXME: change this to status
  resolved?: boolean;

  // Patched state variables
  stateVariables?: LivechatStateVariables;
  userDetails: UserDetailsPayload;
  unreadMessage: number;
  formData?: LivechatFormData;
  waiting_for_assignment?: boolean;
  isRemoved?: boolean;
}
export enum MessageStatus {
  SENDING = "sending",
  SENT = "sent",
  ERROR = "error",
}
export interface InteractionType {
  RowKey: string;
  PartitionKey: string;
  last_modified: string;
  date_created: string;
  Timestamp?: string;
  data: InteractionData[] | InteractionData;
  source: string;
  type: string;
  user_id: string;
  session: string;
  error?: string;
  status?: MessageStatus;
}

export interface LivechatStateVariables {
  fullName?: string;
  alias?: string;
  mobileNo?: string;
  email?: string;
  source?: string;
  profile_pic?: string;
  session_history?: string;
  channel?: string;
  userQueryTopic?: string;
}

export interface InteractionData {
  event?: string;
  text?: string;
  data?: { text: string }; // FIXME: not sure what is this
  content?: ICard[];
}

export interface LivechatFormData {
  customerName?: string;
  customerId?: string;
  customerProfile?: string;
  interactionType?: string;
  issueCategorization?: string;
  issueDescription?: string;
  employer?: string;
  enquirerCompany?: string;
  mobilePhone?: string;
  homePhone?: string;
  officePhone?: string;
  actionDetails?: string;
  agentName?: string;
  agentSoeId?: string;
  status?: AgentStatusType;
}

export type AgentStatusType = "ONLINE" | "OFFLINE" | "AWAY";
