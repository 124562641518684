<script>
import { Line } from "vue-chartjs";
import moment from "moment-timezone";

moment.tz.setDefault("Asia/Singapore");

export default {
  extends: Line,
  props: {
    datasets: {
      default() {
        return [];
      },
    },
    labels: {
      default() {
        return [];
      },
    },
    colors: {
      default() {
        return [];
      },
    },
  },
  computed: {
    dataWithColor() {
      let result = this.datasets;
      for (let i = 0; i < this.colors.length; i++) {
        result[i].backgroundColor = this.colors[i];
      }
      return result;
    },
  },
  methods: {
    initializeChart() {
      this.renderChart(
        {
          // , moment.ISO_8601
          labels: this.labels.map((lab) => moment(lab).format("MMM D")),
          datasets: this.dataWithColor,
        },
        {
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: true,
                  maxRotation: 0,
                },
              },
            ],
          },
        }
      );
    },
  },
  mounted() {
    this.initializeChart();
  },
  watch: {
    datasets() {
      this.initializeChart();
    },
  },
};
</script>
