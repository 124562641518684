<template>
  <el-form label-position="left" label-width="100px" :model="form">
    <el-form-item label="ID">
      <el-input :value="form.id" readonly></el-input>
    </el-form-item>
    <el-form-item label="Name">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="Description">
      <el-input v-model="form.description"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "FormDetailBasic",
  props: {
    form: Object,
  },
};
</script>

<style scoped></style>
