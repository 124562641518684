import { OutreachState } from "./types";

const state: OutreachState = {
  contacts: {
    data: [],
    count: 0,
  },
  stages: {
    data: [],
  },
  tags: {
    data: [],
  },
  isLoading: false,
  filterBoard: [],
  sortBoard: [],
  contactAttributes: [],
};

export default state;
