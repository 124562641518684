<template>
  <el-row v-loading="isLoading">
    <div class="outreach-create-contacts">
      <el-form label-position="top" :rules="ruleForm" :model="form" ref="contact-form">
        <el-row :gutter="5" style="padding: 10px; margin-bottom: 0px">
          <el-col :span="24">
            <el-form-item label="First Name" prop="firstName">
              <el-input v-model="form.firstName" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Last Name" prop="lastName">
              <el-input v-model="form.lastName" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Primary Contact" prop="primaryContact">
              <el-input
                v-model="form.primaryContact"
                @input="validatePrimaryContact"
                maxlength="30"
              ></el-input>
              <transition name="el-slide-fade">
                <p v-show="errors.primaryContact" class="el-form-item__error" style="margin: 0">
                  {{ errors.primaryContact }}
                </p>
              </transition>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Country" prop="country">
              <el-input v-model="form.country" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="Channel" prop="channel">
              <el-select
                v-model="form.channel"
                collapse-tags
                class="outreach-tab-contacts-table-select"
              >
                <el-option v-for="(item, i) in channel" :key="i" :label="item" :value="item">
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="5" style="padding: 10px; margin-bottom: 0">
          <el-col :span="24" style="margin-right: 12px">
            <el-form-item label="Tags" prop="tags">
              <el-select
                v-model="form.tags"
                multiple
                class="outreach-tab-contacts-table-select"
                style="color: black !important"
              >
                <el-option
                  v-for="(item, i) in contactsTags.data"
                  :key="i"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="24">
            <el-form-item label="Stage" prop="stage">
              <el-select
                v-model="form.stage"
                collapse-tags
                class="outreach-tab-contacts-table-select"
                style="color: black !important"
              >
                <el-option
                  v-for="(item, i) in contactsStages.data"
                  :key="i"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="5" style="padding: 10px">
          <el-col :span="24" v-for="item in contactAttributes" :key="`attribute-${item.id}`">
            <el-form-item>
              <p v-if="item.type === ATTRIBUTE_TYPE.TEXT_BOX">
                {{ item.label }}
                <el-input
                  :value="getAttributeContactValue(item)"
                  @input="setAttributeContacts(item, $event)"
                  maxlength="30"
                ></el-input>
              </p>
              <div v-else-if="item.type === ATTRIBUTE_TYPE.CHECKBOX">
                <div>{{ item.label }}</div>
                <div>
                  <el-checkbox
                    :checked="getAttributeContactValue(item) != ''"
                    @change="setAttributeContacts(item, $event)"
                  />
                </div>
              </div>
              <p v-else-if="item.type === ATTRIBUTE_TYPE.DROPDOWN">
                {{ item.label }}
                <el-form-item>
                  <el-select
                    @input="setAttributeContacts(item, $event)"
                    :value="getAttributeContactValue(item)"
                    collapse-tags
                    class="outreach-tab-contacts-table-select"
                  >
                    <el-option v-for="(item, i) in item.value" :key="i" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item style="text-align: right">
          <el-button
            type="primary"
            size="large"
            @click="submitForm(form)"
            :disabled="!checkIsPhoneNumber(form.primaryContact)"
            style="
              color: white !important;
              padding: 10px 30px !important;
              font-size: 16px !important;
            "
            >Save</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
const CONTACT_CREATE = require("@/graphql/OutReachContactCreate.graphql");
import { checkIsPhoneNumber } from "@/helperMethods/outreach";
import {
  ATTRIBUTE_TYPE,
  optionCheckbox,
  OPTION_CHECKBOX,
} from "@/helperMethods/outreach/attribute";

export default Vue.extend({
  props: {
    dialogTableVisible: Boolean,
  },
  data() {
    return {
      err: undefined,
      isDisabled: false,
      form: {
        firstName: "",
        lastName: "",
        channel: "Whatsapp",
        primaryContact: "",
        tags: "",
        stage: "",
        country: "",
      },
      ruleForm: {
        firstName: [{ required: true, message: "Please enter your first name", trigger: "blur" }],
        lastName: [{ required: true, message: "Please enter your last name", trigger: "blur" }],
        primaryContact: [
          {
            required: true,
            message: "Please enter your primary contact",
            trigger: ["blur", "change"],
          },
        ],
      },
      channel: ["Whatsapp", "Email", "Phone", "Web chat"],
      checkIsPhoneNumber,
      errors: {
        primaryContact: "",
      },
      attributeContacts: [],
      ATTRIBUTE_TYPE,
      optionCheckbox: optionCheckbox(),
      OPTION_CHECKBOX,
    };
  },
  computed: {
    ...mapGetters(["contactsTags", "contactsStages", "contactAttributes"]),
    isLoading() {
      return this.$store.state.outreach.isLoading;
    },
  },
  methods: {
    async submitForm() {
      this.$refs["contact-form"].validate(async (valid) => {
        if (!valid) return false;

        this.$store.commit("SET_OUTREACH_LOADING", true);
        const self = this;
        const dataContacts = this.form;
        const { tags = [], stage = "" } = this.form;
        dataContacts.tags = [...this.contactsTags.data.filter((tag) => tags.includes(tag.name))];
        dataContacts.tags = [_.map(dataContacts.tags, (entity) => entity.name)].toString();
        const attributeContacts = this.attributeContacts;
        if (stage) {
          dataContacts.stage = this.contactsStages.data.find(
            (stage) => stage.name === dataContacts.stage
          ).name;
        }
        await this.$apollo
          .mutate({
            mutation: CONTACT_CREATE,
            variables: {
              contacts: dataContacts,
              attributes: attributeContacts,
            },
            update(store, { data: { outReachContactAPI } }) {
              self.$notify.success({
                title: "Success",
                position: "bottom-right",
                message: `Successfully create the contacts.`,
              });
              self.$store.dispatch("FETCH_OUTREACH_SEGMENTS");
              self.$emit("close-dialog", true);
              self.$parent.$emit("refetch-data", true);
            },
          })
          .catch((err) => {
            self.fileList = [];
            self.uploadedContactList = [];
            self.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Failed to import contact file!`,
            });
            self.$store.commit("SET_OUTREACH_LOADING", false);
          });
      });
    },
    validatePrimaryContact(primaryContact) {
      this.errors.primaryContact =
        checkIsPhoneNumber(primaryContact) || !primaryContact
          ? ""
          : "This primary contact is invalid for Whatsapp";
    },
    resetForm() {
      this.$refs["form"].resetFields();
    },
    joinListTag(tags) {
      if (!tags || tags.length === 0) return "";
      return tags.join(", ");
    },
    setAttributeContacts(item, value) {
      const previousAttributeContact = _.find(
        this.attributeContacts,
        (entity) => entity.attributeId == item.id
      );
      if (!previousAttributeContact) {
        this.attributeContacts.push({
          attributeId: item.id,
          value,
        });
      } else {
        previousAttributeContact.value = value;
      }
    },
    getAttributeContactValue(item) {
      const attribute = _.find(this.attributeContacts, (entity) => entity.attributeId == item.id);
      return attribute ? attribute.value : "";
    },
    getOptionFromDropDownValue(itemValue) {
      if (!itemValue.length) return [];
      return [
        ...itemValue.map((value) => ({
          label: value,
          value,
        })),
      ];
    },
  },
  watch: {
    dialogTableVisible: {
      handler() {
        Object.keys(this.form).forEach((entity) => (this.form[entity] = ""));
        this.attributeContacts = [];
      },
    },
  },
});
</script>

<style lang="scss">
.outreach-create-contacts {
  font-family: "Montserrat" !important;
  font-size: 15px;
  padding: 0 10px;
  padding-left: 0;
  text-align: left !important;
  max-height: 500px;
  overflow-y: scroll;
  .error-input {
    margin: 0;
    position: absolute;
    padding: 0 5px;
    color: red;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
}
</style>

<style>
.el-slide-fade-enter-active,
.el-slide-fade-leave-active {
  transition: all 0.3s;
}

.el-slide-fade-enter,
.el-slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
