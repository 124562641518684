<template>
  <el-popover
    :visible-arrow="false"
    popper-class="ui_component_list"
    placement="bottom"
    width="300"
    trigger="click"
  >
    <div class="ui_component_list_header">
      <span>Component List</span>
    </div>
    <div class="ui_component_list_body">
      <div
        class="ui_component_list_btn"
        v-for="(fieldComponent, index) in uiComponents"
        :key="index"
      >
        <el-button
          plain
          size="mini"
          :icon="fieldComponent.iconClass"
          @click="
            $emit('addFormUIComponent', {
              type: fieldComponent.type,
              isParentField,
              dependency,
              parentField,
            })
          "
          >{{ fieldComponent.name }}</el-button
        >
      </div>
    </div>
    <el-button size="mini" plain slot="reference" icon="el-icon-plus">Add Component</el-button>
  </el-popover>
</template>

<script>
export default {
  props: {
    isParentField: {
      type: Boolean,
      default: false,
    },
    dependency: {
      type: Object,
      default: () => null,
    },
    parentField: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uiComponents: [
        { name: "Text Field", iconClass: "el-icon-edit-outline", type: "text" },
        { name: "Text Area", iconClass: "el-icon-notebook-2", type: "textarea" },
        { name: "Date Field", iconClass: "el-icon-date", type: "date" },
        { name: "Dropdown", iconClass: "el-icon-bottom", type: "dropdown" },
        { name: "Radio Selection", iconClass: "el-icon-s-operation", type: "radio" },
        { name: "Multi Selection", iconClass: "el-icon-check", type: "checkboxes" },
        { name: "Attachment", iconClass: "el-icon-paperclip", type: "file" },
      ],
    };
  },
};
</script>

<style></style>
