export const EVENT_NODE_TYPES = [
  {
    label: "Show Content",
    name: "goto",
  },
  {
    label: "Start Live Chat",
    name: "escalate",
  },
  {
    label: "Start Flow",
    name: "startflow",
  },
  {
    label: "Start Form",
    name: "form_processor",
  },
  {
    label: "Set Language",
    name: "$set_language",
  },
  {
    label: "Set Department",
    name: "$set_department",
  },
  {
    label: "Capture Value",
    name: "capture",
  },
  {
    label: "Set Values",
    name: "set",
  },
  {
    label: "Make API Call",
    name: "callApi",
  },
  {
    label: "Generative AI",
    name: "gpt",
  },
  {
    label: "Embed Content",
    name: "$display",
    isAdvanced: true,
  },
  {
    label: "Azure Queue",
    name: "azure_queue",
    isAdvanced: true,
  },
  {
    label: "Redirect Bot",
    name: "redirect_bot",
    isAdvanced: true,
  },
  {
    label: "Custom Event",
    name: "custom event",
    isAdvanced: true,
  },
];
