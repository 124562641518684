<template>
  <section class="livechat-app">
    <el-row class="livechat-app--top" type="flex" justify="space-between">
      <div>
        <LivechatMonitorPopover
          v-if="getGraphQlWebsocketStatus() === 'connected'"
          :is-connecting="getGraphQlWebsocketStatus() === 'connecting'"
          :queued-chats="queueChatsArray"
        />

        <el-tooltip
          placement="right"
          content="When no agents are online, Livechat will stop accepting users into the queue"
          popper-class="agent-status-tooltip"
        >
          <el-dropdown trigger="click" @command="updateAgentStatus">
            <el-button
              :disabled="updatingAgentStatus"
              :class="{ 'status-button': agentStatusText === 'Online' }"
              :type="agentStatusText === 'Offline' ? 'danger' : ''"
              :plain="agentStatusText === 'Offline'"
              style="border-radius: 4px"
              size="small"
            >
              {{ agentStatusText }}
              <i
                :class="{
                  'el-icon-loading': updatingAgentStatus,
                  'el-icon-arrow-down': !updatingAgentStatus,
                }"
                class="el-icon--right"
              ></i>
            </el-button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(status, index) in agentStatusesArray"
                :key="index"
                :command="status.value"
                :disabled="status.value === agentStatus"
              >
                {{ status.text }}
                <i v-if="status.value === agentStatus" class="el-icon-check"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-tooltip>

        <div style="display: inline-block" />

        <template v-if="!isShowAgentsStatusEnabled">
          <span class="agentDisplay" v-if="getOnlineAgents.length === 0">No agents online</span>
          <small
            class="agentDisplay"
            :key="`agent-email-${agentIndex}`"
            v-for="(agent, agentIndex) in getOnlineAgents"
            v-html="`${agent}${agentIndex < getOnlineAgents.length - 1 ? ', ' : ''}`"
          ></small>
        </template>
      </div>

      <ServerStatus :graphQLWebsocketStatus="getGraphQlWebsocketStatus()" />
    </el-row>

    <el-tabs class="livechat-app--main" v-model="currentTabName" :before-leave="handleTabChange">
      <el-tab-pane name="queue" class="tabpanel" :lazy="true">
        <span slot="label">
          Queue

          <el-badge
            v-if="!fetchQueueChatLoading && showQueueUnassignedBadgeNumber"
            type="primary"
            :value="queueBadgeNumber + '/' + queueUnassignedBadgeNumber"
          />
          <el-badge v-else-if="!fetchQueueChatLoading" type="primary" :value="queueBadgeNumber" />
        </span>

        <QueueChatsTab ref="queueChatsTab" @initResizer="initResizer" />
      </el-tab-pane>

      <el-tab-pane name="resolved" class="tabpanel" :lazy="true">
        <span slot="label">
          Resolved
          <el-badge v-if="!fetchResolvedChatLoading" type="primary" :value="resolvedBadgeNumber" />
        </span>

        <ResolvedChatsTab ref="resolvedChatsTabRef" @initResizer="initResizer" />
      </el-tab-pane>

      <el-tab-pane name="abandoned" v-if="displayAbandonedTab" class="tabpanel" :lazy="true">
        <span slot="label">
          Abandoned
          <el-badge
            v-if="!fetchAbandonedChatLoading"
            type="primary"
            :value="abandonedBadgeNumber"
          />
        </span>

        <AbandonedChatsTab ref="abandonedChatsTabRef" @initResizer="initResizer" />
      </el-tab-pane>

      <el-tab-pane name="monitor" class="tabpanel" v-if="displayMonitorTab" :lazy="true">
        <span slot="label">
          Monitor
          <el-badge v-if="!fetchMonitorChatLoading" type="primary" :value="monitorBadgeNumber" />
        </span>

        <MonitorChatsTab ref="monitor-chats-tab" @initResizer="initResizer" />
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import { notifyWebsocketError } from "@/helperMethods/notifyError";
import ResolvedChatsTab from "@/components/LiveChat/ResolvedChats/Index";
import AbandonedChatsTab from "@/components/LiveChat/AbandonedChats/Index";
import MonitorChatsTab from "@/components/LiveChat/monitorChats/Index";
import QueueChatsTab from "@/components/LiveChat/QueueChatsTab";
import LivechatMonitorPopover from "./LivechatMonitorPopover/Index";
import ServerStatus from "./ServerStatus";
import {
  GRAPHQL_WEBSOCKET_STATE,
  isGraphQLWebsocketConnected,
  isGraphQLWebsocketConnecting,
  listenGraphQLWebsocket,
} from "@/store/api";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    ResolvedChatsTab,
    AbandonedChatsTab,
    QueueChatsTab,
    MonitorChatsTab,
    LivechatMonitorPopover,
    ServerStatus,
  },
  data() {
    return {
      connecting: false,
      updatingAgentStatus: false,
      initializedSocketEvents: false,
      agentStatuses: {
        ONLINE: {
          value: "ONLINE",
          text: "Online",
        },
        OFFLINE: {
          value: "OFFLINE",
          text: "Offline",
        },
      },
      accessToken: "",
    };
  },
  computed: {
    ...mapGetters([
      "queueChatsArray",
      "activeTabName",
      "fetchQueueChatLoading",
      // Both abandoned and resolved chats using the same query and loading
      "fetchResolvedChatLoading",
      "fetchAbandonedChatLoading",
      "fetchMonitorChatLoading",

      "queueBadgeNumber",
      "queueUnassignedBadgeNumber",
      "resolvedBadgeNumber",
      "abandonedBadgeNumber",
      "monitorBadgeNumber",

      "getRoutingMode",

      "dataCollectionFormEnabled",
      "getAgentStatus",
      "getSocketConnectionStatus",
      "getDisableMonitorTabOption",
      "getDisableAbandonedTabOption",
      "getAdditionalAgentStatus",
      "getOnlineAgents",
    ]),
    displayMonitorTab() {
      return !this.getDisableMonitorTabOption;
    },
    displayAbandonedTab() {
      return !this.getDisableAbandonedTabOption;
    },
    agentStatusText() {
      const text = _.get(this.agentStatuses, `${this.getAgentStatus}.text`, "Offline");
      return text;
    },
    agentStatusesArray() {
      return Object.values(this.agentStatuses);
    },
    agentStatus: {
      get() {
        return this.getAgentStatus;
      },
      set(status) {
        this.$store.commit("SET_AGENT_STATUS", status);
      },
    },
    currentTabName: {
      get() {
        return this.activeTabName;
      },
      set(newTabName) {
        this.$store.commit("SET_ACTIVE_TAB_NAME", newTabName);
      },
    },

    /**
     * @description Is show agents status settings enabled from module editor
     * @return {boolean}
     */
    isShowAgentsStatusEnabled() {
      return _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showAgentsStatus",
        false
      );
    },
    showQueueUnassignedBadgeNumber() {
      return (
        this.getRoutingMode !== "assignment" ||
        _.get(this.$store.state, "modules.handover.show_users_waiting_for_assignment", false)
      );
    },
  },
  methods: {
    getGraphQlWebsocketStatus() {
      return this.getSocketConnectionStatus;
    },
    showAgentStatusNotification(newStatus) {
      const agentIsOnline = newStatus === "ONLINE";
      if (agentIsOnline) {
        this.$notify.success({
          message: "You are now online, let's start chatting!",
          title: "Success",
          showClose: true,
          position: "bottom-right",
        });
      } else {
        this.$notify.info({
          title: "Info",
          message: "You are offline, go online to start conversation.",
          showClose: true,
          position: "bottom-right",
        });
      }
    },
    updateAgentStatus(newAgentStatus) {
      if (this.updatingAgentStatus) {
        // Loading state, do nothing
        return;
      }

      this.updatingAgentStatus = true;
      this.$store
        .dispatch("CHANGE_APPEAR_ONLINE", {
          newStatus: newAgentStatus,
          previousStatus: this.getAgentStatus,
        })
        .catch((error) => {
          this.$notify.error({
            title: "Online Error",
            type: "error",
            message: error.message || "Encountered error going online",
            position: "bottom-right",
          });
        })
        .finally(() => (this.updatingAgentStatus = false));
    },
    handleTabChange(activeName, oldActiveName) {
      // Reset selected chat and filters
      this.$store.commit("SELECT_LIVE_CHAT", {
        chatId: null,
        type: "queue",
      });

      this.flushInactiveTabData(oldActiveName);
      this.refetchRevisitedTabData(activeName);

      if (oldActiveName === "monitor" && this.$route.params.sessionId) {
        this.$router.replace("/livechat").catch(() => {});
      }
      this.$nextTick(() => {
        const monitorChatsTabHasInterval = _.get(
          this.$refs,
          "['monitor-chats-tab']['intervalFetchMonitorChats']",
          null
        );

        const monitorLivechatIntervalFetchEnabled = _.get(
          this,
          "$store.state.modules.system.monitorLivechatIntervalFetch.enabled",
          true
        );
        if (this.currentTabName === "monitor" && monitorLivechatIntervalFetchEnabled) {
          this.$refs["monitor-chats-tab"]?.startIntervalFetchMonitorChats();
        } else if (monitorChatsTabHasInterval) {
          clearInterval(this.$refs["monitor-chats-tab"].intervalFetchMonitorChats);
          this.$refs["monitor-chats-tab"].intervalFetchMonitorChats = null;
        }
      });
    },
    setSocketConnectionStatus(status) {
      this.$store.commit("SET_SOCKET_CONNECTION_STATUS", status);
    },
    notifyError(message) {
      this.$notify.error({
        title: "Error",
        message,
        position: "bottom-right",
      });
    },
    flushInactiveTabData(inactiveTabName) {
      if (inactiveTabName === "resolved") {
        this.$store.commit("FLUSH_RESOLVED_CHATS_ARRAY");
      } else if (inactiveTabName === "abandoned") {
        this.$store.commit("FLUSH_ABANDONED_CHATS_ARRAY");
      }
    },
    refetchRevisitedTabData(activeTabName) {
      if (activeTabName === "resolved" && this.$refs.resolvedChatsTabRef) {
        this.$store.commit("SET_FETCH_RESOLVED_CHAT_LOADING", true);
        this.$refs.resolvedChatsTabRef.$apollo?.queries?.resolvedChats.refetch();
      } else if (activeTabName === "abandoned" && this.$refs.abandonedChatsTabRef) {
        this.$store.commit("SET_FETCH_ABANDONED_CHAT_LOADING", true);
        this.$refs.abandonedChatsTabRef.$apollo?.queries?.resolvedChats.refetch();
      }
    },
    initResizer(resizer, sidebar) {
      let isResizing = false;
      let sidebarWidth;
      let mouseDownX;

      function mousedownHandler(e) {
        isResizing = true;
        sidebarWidth = parseInt(window.getComputedStyle(sidebar).width, 10);
        mouseDownX = e.clientX;
        document.addEventListener("mousemove", mousemoveHandler);
      }

      function mousemoveHandler(e) {
        if (!isResizing) return;
        sidebar.style.userSelect = "none";

        const mouseMoveX = e.clientX;
        const diffX = mouseMoveX - mouseDownX;
        const sidebarNewWidth = sidebarWidth + diffX;

        if (sidebarNewWidth < 700) {
          sidebar.style.width = `${sidebarNewWidth}px`;
        }
      }

      resizer.addEventListener("mousedown", mousedownHandler);

      document.addEventListener("mouseup", () => {
        isResizing = false;
        sidebar.style.userSelect = "auto";
        document.removeEventListener("mousemove", mousemoveHandler);
      });
    },
  },
  mounted() {
    // dont erase dispatch("GET_STORAGE_TYPE"), to support urls in media messages
    // ceph storage will have invalid url in livechat messages due to delete attachment feature if we remove this
    this.$store.dispatch("GET_STORAGE_TYPE");

    const onlyForMOM = this.$store.state.account === "MOM";
    this.agentStatuses = Object.assign({}, this.agentStatuses, this.getAdditionalAgentStatus);

    if (this.dataCollectionFormEnabled) {
      this.$store.dispatch("GET_ALL_FIELD_DATA").catch((error) => {
        this.$notify.error({
          title: "Error",
          message: "Encountered error fetching issue categories",
          position: "bottom-right",
        });
      });
    }

    if (isGraphQLWebsocketConnected()) {
      this.setSocketConnectionStatus("connected");
    } else if (isGraphQLWebsocketConnecting()) {
      this.setSocketConnectionStatus("connecting");
    } else {
      this.setSocketConnectionStatus("disconnected");
    }

    this.unsubConnectedWs = listenGraphQLWebsocket(GRAPHQL_WEBSOCKET_STATE.CONNECTED, () => {
      this.updateAgentStatus(this.getAgentStatus);
      this.setSocketConnectionStatus("connected");
    });
    this.unsubReconnectedWs = listenGraphQLWebsocket(GRAPHQL_WEBSOCKET_STATE.RECONNECTED, () => {
      this.updateAgentStatus(this.getAgentStatus);
      this.setSocketConnectionStatus("connected");
    });
    this.unsubConnectingWs = listenGraphQLWebsocket(GRAPHQL_WEBSOCKET_STATE.CONNECTING, () => {
      this.setSocketConnectionStatus("connecting");
    });
    this.unsubErrorWs = listenGraphQLWebsocket(GRAPHQL_WEBSOCKET_STATE.ERROR, (error) => {
      this.setSocketConnectionStatus("disconnected");
      notifyWebsocketError(error);
    });
    this.unsubDisconnectedWs = listenGraphQLWebsocket(GRAPHQL_WEBSOCKET_STATE.DISCONNECTED, () => {
      this.setSocketConnectionStatus("disconnected");
      notifyWebsocketError("Disconnected");
    });

    const sessionId = this.$route.params.sessionId;
    if (sessionId && this.displayMonitorTab) {
      this.$store.commit("SET_ACTIVE_TAB_NAME", "monitor");
    }
  },
  destroyed() {
    this.$store.commit("RESET_ALL_FILTERS");
    this.$store.commit("FLUSH_RESOLVED_CHATS_ARRAY");
    this.$store.commit("FLUSH_ABANDONED_CHATS_ARRAY");
    this.$store.commit("FLUSH_MONITOR_CHATS_ARRAY");
    this.unsubErrorWs();
    this.unsubDisconnectedWs();
    this.unsubConnectedWs();
    this.unsubConnectingWs();
    this.unsubReconnectedWs();
  },
  watch: {
    getAgentStatus(newStatus, oldStatus) {
      this.showAgentStatusNotification(newStatus);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

$border-color: $color-light;
$box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

.livechat-app {
  display: grid;
  grid-template:
    "top"
    "main";
  height: 100%;
  margin: 0;
  grid-template-rows: min-content;

  &--top {
    grid-area: top;
    align-self: stretch;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-top: 8px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 845px) {
    &--top {
      margin-left: 65px;
    }
  }

  &--main {
    grid-area: main;
    align-self: stretch;
    border: 1px solid $border-color;
    box-shadow: $box-shadow;
    border-radius: 5px;

    display: grid;
    grid-template:
      "tab_header"
      "tab_content";
    grid-template-rows: min-content;

    ::v-deep .el-tabs__header {
      grid-area: tab_header;
      align-self: stretch;
      .el-tabs__nav {
        margin-left: 15px;
      }
      .el-tabs__item {
        min-width: 70px;
      }
    }

    ::v-deep .el-tabs__content {
      grid-area: tab_content;
      align-self: stretch;
    }
  }
}

.agentDisplay {
  font-size: 0.7em;
  padding: 0;
  margin-top: 0;
  margin-left: 10px;
}

.el-tabs__content {
  overflow: hidden;
}

.tabpanel {
  overflow: hidden;
  display: grid;
}

.status-button {
  /* line-height: 20px; */
  text-align: center;
  background: white !important;
  color: $color-info !important;
  border-color: $color-info !important;
}

.status-button:hover {
  background: $color-info !important;
  color: white !important;
  border-color: $color-info !important;
}
@media (min-width: 580px) and (max-width: 1024px) {
  .agent-status-tooltip {
    max-width: 45%;
  }

  .alert-messages {
    top: 10% !important;
    left: 53%;
  }
}
</style>

<style>
@media (max-width: 1024px) {
  .agent-status-tooltip {
    max-width: 30%;
    text-align: left;
  }
}
</style>

<style>
.livechat-container--left {
  position: relative;
  min-width: 200px !important;
  width: 400px;
}
.livechat-resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  cursor: col-resize;
  background-color: transparent;
}
</style>
