import _ from "lodash";
import { InteractionType, LivechatSessionType, MessageStatus } from "./types";

export function handleMessageSendingError(
  state: any,
  interactionId: string,
  updatedLivechat: LivechatSessionType,
  commit: any
) {
  // Get latest chat cuz chat can be changed before this catch
  const updatingChat = _.find(state.queueChatObject, (chat) => {
    return (
      chat.user_id === updatedLivechat.user_id && chat.PartitionKey === updatedLivechat.PartitionKey
    );
  });
  const updatingChatInteractions: InteractionType[] = _.cloneDeep(
    _.get(updatingChat, "interactions", [])
  );

  const failedSendingInteraction = _.find(updatingChatInteractions, (interaction) => {
    return interaction.RowKey === interactionId;
  });
  if (failedSendingInteraction) {
    failedSendingInteraction.status = MessageStatus.ERROR;
  }
  updatedLivechat.interactions = updatingChatInteractions;
  commit("UPDATE_QUEUE_CHAT", { updatedLivechat });
}

export function isConnectionAvailable(state: any, isOnline: boolean) {
  const socketConnectionStatus = _.get(state, "socketConnection", "");
  return socketConnectionStatus === "connected" && isOnline;
}
