<template>
  <div class="message-wrapper">
    <div
      style="font-size: 0.875em; display: inline-block"
      slot="reference"
      :class="{
        'message-bubble-left': isUserMessage,
        'message-bubble-right': !isUserMessage,
      }"
    >
      <div
        v-if="message.data.content[0].images"
        v-viewer="$options.viewerOptions"
        style="cursor: pointer"
      >
        <div v-for="(item, index) in fileUrls" :key="index">
          <el-popover
            :placement="isUserMessage ? 'left' : 'right'"
            trigger="hover"
            min-width="50"
            :disabled="item.errorOrDeleted || !enableDeleteAction"
          >
            <el-button
              type="primary"
              slot="reference"
              style="background-color: white; border-color: white"
            >
              <img
                :src="item.url + (isAzureStorage ? '&' + item.cacheTimestamp : '')"
                @error="setError(item)"
              />
            </el-button>
            <div style="display: flex; align-items: center; justify-content: center">
              <el-button type="danger" round size="mini" @click="deleteAttachment(item)"
                >Delete</el-button
              >
            </div>
          </el-popover>
        </div>
      </div>
      <div v-else-if="message.data.content[0].files" v-viewer="$options.viewerOptions">
        <div v-for="(item, index) in fileUrls" :key="index">
          <el-popover
            :placement="isUserMessage ? 'left' : 'right'"
            trigger="hover"
            min-width="50"
            :disabled="item.errorOrDeleted || !enableDeleteAction"
          >
            <el-button
              slot="reference"
              style="white-space: normal; word-wrap: break-word; line-height: 20px"
            >
              <a
                :href="item.url + (isAzureStorage ? '&' + item.cacheTimestamp : '')"
                target="_blank"
                style="text-decoration: none"
              >
                <i class="el-icon-document" style="font-size: 40px" />
                {{ fileNameFromURL(item.url) }}
              </a>
            </el-button>
            <div style="display: flex; align-items: center; justify-content: center">
              <el-button type="danger" round size="mini" @click="deleteAttachment(item)"
                >Delete</el-button
              >
            </div>
          </el-popover>
        </div>
      </div>
      <div
        v-else-if="message.data.content[0].videos"
        v-viewer="$options.viewerOptions"
        style="cursor: pointer"
      >
        <div v-for="(item, index) in fileUrls" :key="index">
          <el-popover
            :placement="isUserMessage ? 'left' : 'right'"
            trigger="hover"
            min-width="50"
            :disabled="item.errorOrDeleted || !enableDeleteAction"
          >
            <el-button
              slot="reference"
              style="white-space: normal; word-wrap: break-word; line-height: 20px"
            >
              <a
                :href="item.url + (isAzureStorage ? '&' + item.cacheTimestamp : '')"
                target="_blank"
                style="text-decoration: none"
              >
                <i class="el-icon-video-camera" style="font-size: 40px" />
                {{ fileNameFromURL(item.url) }}
              </a>
            </el-button>
            <div style="display: flex; align-items: center; justify-content: center">
              <el-button type="danger" round size="mini" @click="deleteAttachment(item)"
                >Delete</el-button
              >
            </div>
          </el-popover>
        </div>
      </div>

      <div style="text-align: right">
        <i style="font-size: 9px">{{
          messageTimestamp(message.Timestamp || message.date_created)
        }}</i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import "viewerjs/dist/viewer.css";

import { mapGetters } from "vuex";

export default {
  name: "File",
  props: ["isUserMessage", "message", "buttonOutline"],
  viewerOptions: {
    inline: false,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: false,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: false,
    fullscreen: false,
    keyboard: true,
  },
  data() {
    return {
      left: 0,
      active: false,
      fileUrls: [],
    };
  },
  mounted() {
    // fetch file url from webchat_request_file_agent endpoint
    // Reason: Allow viewing file for agents and sender only
    this.fetchFileUrl(this.message);
  },
  computed: {
    ...mapGetters(["isAzureStorage"]),
    enableDeleteAction() {
      return _.get(
        this,
        "$store.state.modules.handover.messageActions.enableAttachmentDeleteAction",
        false
      );
    },
  },
  methods: {
    messageTimestamp(timestamp) {
      return moment(timestamp).local().calendar(moment(), {
        sameDay: "[Today at] h:mm A",
        nextDay: "[Tomorrow at] h:mm A",
        nextWeek: "[Next] dddd [at] h:mm A",
        lastDay: "[Yesterday at] h:mm A",
        lastWeek: "DD-MMM-YYYY [at] h:mm A",
        sameElse: "DD-MMM-YYYY [at] h:mm A",
      });
    },
    deleteAttachment(item) {
      const fileName = this.fileNameWithUuidFromUrl(item.url);
      const result = this.$store
        .dispatch("DELETE_FILES", { fileNames: [fileName] })
        .then((result) => {
          if (result.success) {
            this.$message({
              message: "Attachment has been deleted",
              type: "success",
            });
            this.refreshCache(item);
          }
        });
    },
    refreshCache(item) {
      const index = this.fileUrls.indexOf(item);
      if (index > -1) {
        this.fileUrls[index].cacheTimestamp = Date.now();
      }
    },
    setError(item) {
      const index = this.fileUrls.indexOf(item);
      if (index > -1) {
        this.fileUrls[index].errorOrDeleted = true;
        this.fileUrls[index].url =
          "https://via.placeholder.com/468x60?text=Image%20not%20found%20or%20deleted";
      }
    },
    /**
     * Get file UUID and Name from url
     * @param{string} url The SAS URL of a file eg: https://domain/path/uuid/filename.jpg?signature=...
     * @return{string} The path with file uuid and name: uuid/filename.jpg
     */
    fileNameWithUuidFromUrl(url) {
      if (!url) {
        return "";
      }
      let lastIdx = 0;
      let nearestLastIdx = 0;
      for (let i = 0; i < url.length; i++) {
        if (url[i] == "/") {
          nearestLastIdx = lastIdx;
          lastIdx = i;
        }
      }
      return url.slice(nearestLastIdx + 1, url.lastIndexOf("?"));
    },
    fileNameFromURL(item) {
      if (item) {
        return item.slice(item.lastIndexOf("/") + 1, item.lastIndexOf("?"));
      } else {
        return "";
      }
    },
    async fetchFileUrl(message) {
      const { files, videos, images } = _.get(message, "data.content[0]", {});
      const urls = _.compact(files || videos || images);
      try {
        const transformedUrls = _.map(urls, (item) => {
          const { url = item, mimeType = "", caption = "" } = item;
          return url;
        });

        const fileUrls = await this.$store.dispatch("FETCH_MULTIPLE_FILE", transformedUrls);
        this.fileUrls = fileUrls.map((url) => ({
          url,
          cacheTimestamp: Date.now(),
          errorOrDeleted: false,
        }));
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Error fetching image/file",
          message: "Please inform adminstrator of this error.",
        });
      }
    },
  },
};
</script>
<style>
.keyreply-bubble-content {
  word-wrap: break-word;
  text-align: left;
  overflow-y: auto;
  max-height: 400px;
}

.keyreply-bubble {
  position: relative;
  max-width: 300px;
  min-width: 150px;
  padding: 0 8px;
  margin: 8px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
  font-size: 14px;
  padding: 8px;
  display: block;
  overflow: visible;
  word-wrap: break-word;
}

.keyreply-bubble.left {
  float: left;
  /* overflow-y: scroll; */
  /* max-height: 400px; */
}

.keyreply-bubble.right {
  float: right;
  /* overflow-y: scroll; */
  /* max-height: 400px; */
}

.keyreply-bubble:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  bottom: auto;
  border: 11px solid;
}

.keyreply-bubble.right:before {
  right: -8px;
  left: auto;
  border-color: inherit;
}

.keyreply-bubble.left:before {
  left: -8px;
  right: auto;
  border-color: inherit;
}

/* Facebook Messenger */

.keyreply-bubble.left.facebook {
  background: #0084ff;
}

.keyreply-bubble.right.facebook {
  background: #f1f0f0;
  color: black;
}
</style>
