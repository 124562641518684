import { SubscriptionClient } from "subscriptions-transport-ws";
import _, { chain } from "lodash";
import LocalStorageManager from "@/localStorageManager";
import { jsonParse } from "@/helpers/parser";
import { WebSocketLink } from "apollo-link-ws";
import { createHttpLink } from "apollo-link-http";
import fetch from "unfetch";
import { onError } from "apollo-link-error";
import { Notification } from "element-ui";
import { captureGraphQLError } from "@/sentry";
import * as Sentry from "@sentry/browser";
import { ApolloLink, split } from "apollo-link";
import { FragmentDefinitionNode, OperationDefinitionNode } from "graphql";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import { getAuthType, getToken } from "@/auth";
import { RetryLink } from "apollo-link-retry";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { config } from "@/store/api";
import { formatToken } from "@/helperMethods/util";
import catchRedirect from "@/helpers/catchRedirect";

const GQL_SUBSCRIPTION_INACTIVITY_TIMEOUT = 0; // explicitly disable inactivity timeout
const GQL_SUBSCRIPTION_TIMEOUT = 20 * 1000;
const GQL_SUBSCRIPTION_MIN_TIMEOUT = 10 * 1000;

let subscriptionClient: SubscriptionClient;
const subscriptionClientEventListener: Record<string, Function[]> = {};

enum GRAPHQL_WEBSOCKET_STATE {
  CONNECTING = "connecting",
  CONNECTED = "connected",
  RECONNECTING = "reconnecting",
  RECONNECTED = "reconnected",
  DISCONNECTED = "disconnected",
  ERROR = "error",
}

enum WEBSOCKET_READYSTATE {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

const listenGraphQLWebsocket = (eventName: GRAPHQL_WEBSOCKET_STATE, listener: Function) => {
  const unsubFn = () => {
    _.remove(subscriptionClientEventListener[eventName], function (fn) {
      return fn === listener;
    });
  };
  if (!subscriptionClientEventListener[eventName]) {
    subscriptionClientEventListener[eventName] = [listener];
    return unsubFn;
  }
  subscriptionClientEventListener[eventName].push(listener);
  //REMEMBER unsubcribe your listener when your component unmounted
  return unsubFn;
};
const isGraphQLWebsocketConnected = () => {
  return subscriptionClient.status === WEBSOCKET_READYSTATE.OPEN;
};
const isGraphQLWebsocketConnecting = () => {
  return subscriptionClient.status === WEBSOCKET_READYSTATE.CONNECTING;
};

const initGraphQLSubscription = () => {
  const wsUri = chain(new URL("subscriptions", config.root))
    .update("protocol", (protocol) => protocol.replace("http", "ws"))
    .value();

  let userProfile: any;

  const localStoreProfile = LocalStorageManager.getItem("profile");
  if (localStoreProfile) {
    userProfile = jsonParse(localStoreProfile);
  }

  console.info("WS with profile", !!userProfile);

  //See https://github.com/apollographql/subscriptions-transport-ws#constructorurl-options-websocketimpl
  subscriptionClient = new SubscriptionClient(wsUri.href, {
    connectionParams: userProfile,
    timeout: GQL_SUBSCRIPTION_TIMEOUT,
    minTimeout: GQL_SUBSCRIPTION_MIN_TIMEOUT,
    reconnect: true,
    lazy: true,
    inactivityTimeout: GQL_SUBSCRIPTION_INACTIVITY_TIMEOUT,
  });

  Object.values(GRAPHQL_WEBSOCKET_STATE).forEach((state: string) => {
    subscriptionClient.on(state, (...args) => {
      _.forEach(subscriptionClientEventListener[state], (fn: Function) => {
        fn(...args);
      });
    });
  });

  const wsLink = new WebSocketLink(subscriptionClient);
  return { subscriptionClient, wsLink };
};

// Building apollo link chain
// TODO: For apollo-client@3.x there will be a method `setLink`,
//  We can use that to reconfigure the link of created client
const buildApolloLink = (withWsLink = false) => {
  const httpLink = createHttpLink({
    uri: new URL("graphql", config.root).href,
    fetch: fetch,
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach((err) => {
        const errCode = _.get(err, "extensions.code");

        switch (errCode) {
          case "UNAUTHENTICATED":
            Notification.error({
              title: "Authentication Error",
              message: "Please refresh the page. Your token is expired",
              position: "bottom-right",
            });

            // retry the request, returning the new observable
            break;
          case "FORBIDDEN":
            // TODO: deal with the lack of permissions
            break;
          case "401_UNAUTHORIZED":
            catchRedirect({
              response: {
                status: 401,
              },
            });
            break;
          default:
        }
        captureGraphQLError(operation, err);
        return forward(operation);
      });

    if (networkError) {
      Sentry.captureException(new Error("Network error: " + JSON.stringify(networkError)));
      console.log("[Network error]:", networkError);
    }
  });

  // Create the subscription websocket link
  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  let graphqlLink;

  if (withWsLink) {
    const { wsLink } = initGraphQLSubscription();
    graphqlLink = split(
      // split based on operation typer
      ({ query }: { query: any }) => {
        const definition: OperationDefinitionNode | FragmentDefinitionNode =
          getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wsLink,
      httpLink
    );
  } else {
    graphqlLink = httpLink;
  }

  const asyncAuthLink = setContext(async (request) => {
    return {
      headers: {
        authorization: await getToken().then(formatToken),
        "Authorization-Client": getAuthType(),
      },
    };
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: (count, operation, error) => {
      return !!error && error.statusCode !== 401 && error.statusCode !== 403 && count < 5;
    },
  });

  return [asyncAuthLink, errorLink, retryLink, graphqlLink];
};

let apolloClient: any = null;

const buildApolloClient = (apolloLink: ApolloLink) => {
  if (apolloClient) {
    return apolloClient;
  }

  apolloClient = new ApolloClient({
    link: apolloLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: false,
  });

  return apolloClient;
};

const initGraphQL = (withWsLink = true) => {
  console.info("initializing GraphQL");
  const links = buildApolloLink(withWsLink);
  const apolloLink = ApolloLink.from(links);
  return buildApolloClient(apolloLink);
};

export {
  initGraphQL,
  isGraphQLWebsocketConnecting,
  isGraphQLWebsocketConnected,
  listenGraphQLWebsocket,
  GRAPHQL_WEBSOCKET_STATE,
};
