import { ActionTree } from "vuex";
import { graph, rest } from "@/store/api";
import { RootState } from "@/store/types";

import gql from "graphql-tag";
import _ from "lodash";
import { FlowEditorState } from "./types";

const actions: ActionTree<FlowEditorState, RootState> = {
  FETCH_FLOWS: ({ state, commit }) => {
    commit("SET_FETCHING_FLOWS", true);
    return new Promise((resolve, reject) => {
      graph
        .query({
          query: gql`
            query {
              Bot {
                flows
                flowContent
                flowTrigger
              }
            }
          `,
          fetchPolicy: "network-only",
        })
        .then((payload) => {
          const data: any = _.cloneDeep(payload.data);
          commit("SET_FLOWS", data.Bot.flows);
          commit("SET_FLOW_CONTENT", data.Bot.flowContent);
          commit("SET_FLOW_TRIGGER", data.Bot.flowTrigger);
          resolve(data.Bot.flows);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_FETCHING_FLOWS", false);
        });
    });
  },
  FETCH_ALL_FLOWS_RESULTS: ({ commit }, { startDate, endDate, limit, offset, flowIds }) => {
    return graph
      .query({
        query: gql`
          query(
            $startDate: String
            $endDate: String
            $flowIds: [String]
            $limit: Int
            $offset: Int
          ) {
            flowEditorAPI {
              getFlowResults(
                startDate: $startDate
                endDate: $endDate
                limit: $limit
                offset: $offset
                flowIds: $flowIds
              )
            }
          }
        `,
        variables: { startDate, endDate, limit, offset, flowIds },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        // console.log("FETCH_ALL_FLOWS_RESULTS Response: ", response);
        const data = _.get(response, "data.flowEditorAPI.getFlowResults");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  CREATE_FLOW: ({ commit }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            flowEditorAPI {
              createFlow
            }
          }
        `,
      })
      .then((response) => {
        const data = _.get(response, "data.flowEditorAPI.createFlow");
        commit("ADD_FLOW", data);
        return data;
      });
  },
  SAVE_FLOW: ({ commit }, { flowId, flowObject }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($flowId: String!, $flowObject: JSON!) {
            flowEditorAPI {
              saveFlow(flowId: $flowId, flowObject: $flowObject)
            }
          }
        `,
        variables: { flowId, flowObject },
      })
      .then((response) => {
        console.log("SAVE_FLOW Response: ", response);
        const data = _.get(response, "data.flowEditorAPI.saveFlow");
        return data;
      });
  },
  SAVE_FLOWS: ({ state, commit }, { flows }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($flows: JSON) {
            setFlows(flows: $flows)
          }
        `,
        variables: { flows },
      })
      .then((response) => {
        if (response.data) {
          commit("SET_FLOWS", { flows: _.get(response.data, "setFlows") });
        }
      });
  },
  DELETE_FLOW: ({ commit }, { flowId }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($flowId: String!) {
            flowEditorAPI {
              deleteFlow(flowId: $flowId)
            }
          }
        `,
        variables: { flowId },
      })
      .then((response) => {
        console.log("DELETE_FLOW Response: ", response);
        const data = _.get(response, "data.flowEditorAPI.deleteFlow");
        commit("DELETE_FLOW", flowId);
        return data;
      });
  },

  DELETE_SUGGESTED_ANSWER: ({ commit }, { suggestedAnswerId }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($suggestedAnswerId: String!) {
            flowEditorAPI {
              deleteSuggestedAnswer(suggestedAnswerId: $suggestedAnswerId)
            }
          }
        `,
        variables: { suggestedAnswerId },
      })
      .then((response) => {
        console.log("DELETE_FLOW Response: ", response);
        const data = _.get(response, "data.flowEditorAPI.deleteSuggestedAnswer");
        return data;
      })
      .catch((err) => {
        return err;
      });
  },
  CREATE_NEW_SECTION: ({ commit }, { flow }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($flow: JSON!) {
            flowEditorAPI {
              createStandardFlowSection(flow: $flow)
            }
          }
        `,
        variables: { flow },
      })
      .then((response) => {
        console.log("CREATE_NEW_SECTION Response: ", response);
        const data = _.get(response, "data.flowEditorAPI.createStandardFlowSection");
        // commit("ADD_FLOW_SECTION", {
        //   flow,
        //   section: data,
        // })
        return data;
      });
  },
  CREATE_NEW_QUESTION: (store, { section, newQuestionType }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($section: JSON!, $newQuestionType: String!) {
            flowEditorAPI {
              createStandardFlowQuestion(section: $section, newQuestionType: $newQuestionType)
            }
          }
        `,
        variables: { section, newQuestionType },
      })
      .then((response) => {
        console.log("CREATE_NEW_QUESTION Response: ", response);
        const data = _.get(response, "data.flowEditorAPI.createStandardFlowQuestion");
        return data;
      });
  },
  CREATE_NEW_SUGGESTED_ANSWER: (store, { questionId, answerType, question }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($questionId: String!, $answerType: String!, $question: JSON!) {
            flowEditorAPI {
              createStandardFlowSuggestedAnswer(
                questionId: $questionId
                answerType: $answerType
                question: $question
              )
            }
          }
        `,
        variables: { questionId, answerType, question },
      })
      .then((response) => {
        console.log("CREATE_NEW_SUGGESTED_ANSWER Response: ", response);
        const data = _.get(response, "data.flowEditorAPI.createStandardFlowSuggestedAnswer");
        return data;
      });
  },
  PREVIEW_FLOW: (store, { sectionId, sourceType, senderId, recipientId }) => {
    // workflow_trigger_twilio_preview;
    // workflow_trigger_webchat_preview;
    // workflow_trigger_telegram_preview;
    // workflow_trigger_facebook_preview;
    if (sourceType === "twilio") {
      return rest("post", "workflow_trigger_sms_preview", {
        sectionId: sectionId,
        name: "joshua",
        phoneNumber: "+123456789",
        triggerTime: 1546409017,
      });
    } else if (sourceType === "webchat") {
      return rest("post", "workflow_trigger_webchat_preview", {
        sectionId: sectionId,
        sender: {
          id: senderId,
        },
        recipient: {
          id: recipientId,
        },
        triggerTime: 1546409017,
      });
    }
  },
  DELETE_FLOW_QUESTION: ({ state, commit }, { flowId, sectionId, questionId }) => {
    const _flows = _.cloneDeep(state.flows);
    const _sectionIndex = _flows[flowId].sections.findIndex(
      (section) => section.sectionId === sectionId
    );
    if (_sectionIndex >= 0) {
      const _questionIndex = _flows[flowId].sections[_sectionIndex].questions.findIndex(
        (question) => question.questionId === questionId
      );
      _flows[flowId].sections[_sectionIndex].questions.splice(_questionIndex, 1);
      commit("SET_FLOWS", _flows);
      return _questionIndex;
    }
  },
  PREVIEW_REPLY: (store, { message, sourceType, postback, senderId, recipientId }) => {
    if (sourceType === "twilio") {
      return rest("post", "receive_sms", {
        message: message,
        phoneNumber: "+123456789",
        preview: true,
      });
    } else if (sourceType === "webchat") {
      if (message) {
        // TODO: @REMY, make sure senderId = user of the dashboard
        // recipientId = keyreply
        return rest("post", "webchat", {
          message: {
            text: message,
            isPreview: true,
          },
          sender: {
            id: senderId,
          },
          recipient: {
            id: recipientId,
          },
        });
      } else {
        return rest("post", "webchat", {
          postback: {
            ...postback,
            isPreview: true,
          },
          sender: {
            id: senderId,
          },
          recipient: {
            id: recipientId,
          },
        });
      }
    }
  },
};
export default actions;
