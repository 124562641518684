<template>
  <div class="tab-segments">
    <Tag style="margin-bottom: 32px" />
    <Stage />
  </div>
</template>

<script>
import Tag from "./Tag/Index.vue";
import Stage from "./Stage/Index.vue";
export default {
  components: { Tag, Stage },
  mounted() {
    this.$store.dispatch("FETCH_OUTREACH_SEGMENTS");
  },
  data() {
    return {
      data: [
        {
          id: "1",
          name: "Jina Lam",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.tab-segments {
  padding: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .outreach-tag-segments,
  .outreach-stasge-segments {
    // min-width: 48%;
    flex-grow: 1;
  }
}
</style>
