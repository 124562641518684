<template>
  <div v-if="value.event === 'glossary_search'">
    <GlossarySearch :value="value" />

    <!-- <i class="el-icon-success" style="align-self:center;color:green;"></i>
    <i class="el-icon-error" style="align-self:center;color:red;"></i>-->
  </div>
  <div v-else-if="value.event === 'whatsapp_send_template_message'">
    <el-form :model="value.data">
      <el-form-item label="From">
        <el-select
          style="width: 100%"
          filterable
          @change="fetchMessageTemplates"
          v-model="value.data.from"
          placeholder="From"
        >
          <el-option
            v-for="server in $store.state.whatsapp.servers"
            :key="server.url"
            :label="server.wa_id"
            :value="server.url"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="To">
        <el-select
          allow-create
          filterable
          style="width: 100%"
          v-model="value.data.to"
          placeholder="To"
        >
          <el-option :label="'Current User'" :value="'sender.id'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Template Name">
        <el-autocomplete
          :disabled="!value.data.from"
          v-model="value.data.template_name"
          :fetch-suggestions="fetchTemplateMessageNames"
          placeholder="Please input"
          @select="selectMessageTemplate"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="Language">
        <el-autocomplete
          :disabled="!value.data.template_name"
          v-model="value.data.template_language"
          :fetch-suggestions="fetchTemplateMessageLanguages"
          placeholder="Please input"
          @select="selectMessageTemplate"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="Sample Message">
        <el-input
          type="textarea"
          autosize
          placeholder="Please input"
          v-model="templateMessageBody"
        />
        <div v-for="(kv, index) in value.data.parameters" :key="index">
          <el-input
            :disabled="true"
            v-model="kv.key"
            style="width: 20%"
            type="text"
            placeholder="Key"
          />
          <el-select
            v-model="kv.value"
            filterable
            allow-create
            placeholder="Value"
            style="width: 20%; padding: 5px"
          >
            <el-option
              :key="index"
              v-for="(captureKey, index) in captureValues"
              :label="captureKey"
              :value="captureKey"
            ></el-option>
          </el-select>
          <el-checkbox v-model="kv.literal" label="Literal?" style="padding: 5px" />
        </div>
      </el-form-item>
    </el-form>
  </div>

  <!-- API -->
  <div v-else-if="value.event === 'api'">
    <CustomEventAPI v-model="value" />
  </div>
  <div v-else-if="value.event === 'handover_router'"></div>
  <div v-else>
    <el-radio-group v-model="dataType">
      <el-radio-button label="string">Text</el-radio-button>
      <el-radio-button label="number">Number</el-radio-button>
      <el-radio-button label="boolean">Boolean</el-radio-button>
      <el-radio-button label="object">Object</el-radio-button>
      <el-radio-button v-if="previousNode && previousNode.event === 'capture'" label="capturedValue"
        >Captured Value</el-radio-button
      >
    </el-radio-group>
    <el-input
      v-if="dataType === 'string'"
      v-model="value.data"
      style="margin-top: 15px"
      placeholder="value"
    >
      <template slot="prepend">Data</template>
    </el-input>

    <el-input
      v-else-if="dataType === 'number'"
      v-model.number="value.data"
      style="margin-top: 15px"
      placeholder="value"
    >
      <template slot="prepend">Data</template>
    </el-input>

    <div v-else-if="dataType === 'boolean'" style="padding: 8px">
      <el-switch v-model="value.data" active-text="True" inactive-text="False" />
    </div>

    <div v-else-if="dataType === 'object'" style="padding: 8px">
      <el-switch v-model="showObject" active-text="Show" inactive-text="Hide" />
      <JSONEditor
        v-if="showObject"
        ref="jsonEditor"
        v-model="value.data"
        style="margin-top: 10px; border: 2px solid #ccc"
      />
    </div>
  </div>
</template>

<script>
import CustomEventAPI from "@/components/CustomEvent/Api/index.vue";
import GlossarySearch from "@/components/CustomEvent/GlossarySearch/index.vue";
import JSONEditor from "@/components/JSONEditor";

import _ from "lodash";
import Vue from "vue";

export default {
  name: "EventNodeTypeCustomEvent",
  components: { CustomEventAPI, GlossarySearch, JSONEditor },
  props: {
    value: {
      event: "",
      data: {},
    },
    previousNode: {},
  },
  data() {
    return {
      whatsappMessageTemplates: {},
      previousParameters: [],
      showObject: false,
    };
  },
  computed: {
    captureValues() {
      return _.chain(this.$store.state.nodes.trigger)
        .filter((triggerValue, triggerName) => {
          return triggerValue.event === "capture";
        })
        .map((triggerValue, triggername) => {
          return _.get(triggerValue, "data.key");
        })
        .compact()
        .value();
    },
    templateMessageBody: {
      get() {
        const messageTemplates = _.get(this.whatsappMessageTemplates, this.value.data.from, []);
        const templateMessage = _.find(
          messageTemplates,
          (obj) =>
            obj.name === this.value.data.template_name &&
            obj.language === this.value.data.template_language
        );
        if (templateMessage) {
          let body = _.get(templateMessage, "components[0].text", "");
          for (const kv of this.value.data.parameters) {
            if (kv.value) {
              let value = `\${${kv.value}}`;
              if (kv.literal) {
                value = kv.value;
              }
              body = body.replace(kv.key, value);
            }
          }
          return body;
        }
        return "";
      },
    },
    dataType: {
      get() {
        if (this.value.data === null) {
          return "capturedValue";
        } else {
          return typeof this.value.data;
        }
      },
      set(type) {
        console.log(type);
        if (type !== this.dataType) {
          switch (type) {
            case "string":
              this.value.data = "";
              break;
            case "number":
              this.value.data = 1;
              break;
            case "boolean":
              this.value.data = true;
              break;
            case "object":
              this.value.data = {};
              break;
            case "capturedValue":
              this.value.data = null;
              break;
          }
        }
      },
    },
  },
  watch: {
    "value.event": function () {
      const isDataFormatValid = typeof this.value.data === "object";
      if (this.value.event === "glossary_search") {
        const defaultGlossarySearchData = {
          url: "https://example.com",
          searchConditions: [],
          fuzzySearch: true,
          similarityThreshold: 0.2,
          resultType: "default",
          conditionType: "and",
          caching: true,
          cacheDuration: 30,
          mappings: [],
          result: {
            content: {
              text: "Text",
              buttons: [],
            },
            department: ["general"],
            modified: true,
            id: "conversation_start",
            new: false,
            options: {
              disableTextInput: false,
            },
            quickReplies: [],
          },
          fallback: {
            event: "goto",
            data: "fallback_too_complex",
          },
        };
        if (!isDataFormatValid) {
          this.value.data = defaultGlossarySearchData;
        } else {
          this.value.data = {
            ...defaultGlossarySearchData,
            ...this.value.data,
          };
        }
      } else if (this.value.event === "whatsapp_send_template_message") {
        if (!isDataFormatValid) {
          this.value.data = {
            from: "",
            to: "",
            template_name: "",
            template_language: "",
            parameters: [],
          };
        }
        const from = _.get(this, "value.data.from", "");
        if (from) {
          this.fetchMessageTemplates();
        }
      } else if (this.value.event === "api") {
        if (!isDataFormatValid) {
          this.value.data = {
            URL: "www.example.com",
            methodType: "GET",
            requestHeaders: [
              {
                key: "",
                value: "",
              },
            ],
            parameters: [
              {
                key: "",
                value: "",
              },
            ],
          };
        }
      }
    },
  },
  mounted() {
    if (this.value.event === "whatsapp_send_template_message") {
      const from = _.get(this, "value.data.from", "");
      if (from) {
        this.fetchMessageTemplates();
      }
    }
  },
  methods: {
    handleShowObject() {
      this.showObject = !this.showObject;
      this.$refs.jsonEditor.codemirror.refresh();
    },
    selectMessageTemplate() {
      const messageTemplates = _.get(this.whatsappMessageTemplates, this.value.data.from, []);
      const templateMessage = _.find(
        messageTemplates,
        (obj) =>
          obj.name === this.value.data.template_name &&
          obj.language === this.value.data.template_language
      );
      const matches = _.get(templateMessage, "components[0].text", "").match(/({{\d+}})/g);
      const newParamsToRetain = _.get(this.value.data, "parameters", []);
      const shouldPreviousParametersBeReplaced =
        this.previousParameters.length <= newParamsToRetain.length;
      if (shouldPreviousParametersBeReplaced) {
        this.previousParameters = _.cloneDeep(newParamsToRetain);
      } else {
        this.previousParameters = this.previousParameters.map((param, index) => {
          if (newParamsToRetain[index]) {
            return newParamsToRetain[index];
          }
          return param;
        });
      }

      Vue.set(
        this.value.data,
        "parameters",
        _.map(matches, (match, index) => {
          const param = this.previousParameters[index];
          let value = "";
          let literal = false;
          const valueExists = param && param.value;
          const isLiteralExists = param && param.literal;
          if (valueExists) {
            value = param.value;
          }
          if (isLiteralExists) {
            value = param.literal;
          }
          return {
            key: `{{${index + 1}}}`,
            value,
            literal,
          };
        })
      );
    },
    fetchMessageTemplates() {
      this.$store
        .dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
          data: { wa_endpoint: this.value.data.from },
        })
        .then((data) => {
          Vue.set(this.whatsappMessageTemplates, this.value.data.from, data);
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Failed to fetch WhatsApp message templates",
          });
        });
    },
    fetchTemplateMessageLanguages(queryString, cb) {
      const messageTemplates = _.get(this.whatsappMessageTemplates, this.value.data.from, []);
      cb(
        _.filter(
          messageTemplates,
          (obj) => obj.status === "APPROVED" && obj.name.includes(this.value.data.template_name)
        ).map((obj) => {
          return { value: obj.language };
        })
      );
    },
    fetchTemplateMessageNames(queryString, cb) {
      const messageTemplates = _.get(this.whatsappMessageTemplates, this.value.data.from, []);
      cb(
        _.filter(
          messageTemplates,
          (obj) => obj.status === "APPROVED" && (!queryString || obj.name.includes(queryString))
        ).map((obj) => {
          return { value: obj.name };
        })
      );
    },
  },
};
</script>
<style scoped>
.autocomplete-list {
  margin-top: 15px;
  width: 100%;
}
.el-scrollbar ul {
  width: -moz-max-content; /* Firefox/Gecko */
  width: -webkit-max-content; /* Chrome */
}

.el-form-item {
  margin-bottom: 22px;
}
</style>
