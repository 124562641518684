<template>
  <div style="display: grid">
    <!-- SEARCH CONTAINER -->
    <el-row
      :gutter="20"
      type="flex"
      align="middle"
      class="search-container"
      data-cy="search-container"
    >
      <!-- SEARCH BY TEXT -->
      <el-col :span="6">
        <el-input
          v-model="search"
          size="mini"
          placeholder="Search"
          clearable
          suffix-icon="el-icon-search"
        />
      </el-col>
      <!-- SEARCH BY DATE -->
      <el-col :span="18">
        <small style="font-weight: bold">Filter by date range</small>
        <el-date-picker
          v-model="searchDateRange"
          type="daterange"
          size="mini"
          format="yyyy-MM-dd"
          style="width: 400px; margin-left: 15px; margin-bottom: 0"
          align="right"
          @change="$emit('searchDateChange', $event)"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-col>
    </el-row>

    <!-- TABLE -->
    <el-table :data="resultsData" size="mini" ref="resultTable" @row-click="expandRow">
      <!-- COLLAPSABLE DETAIL -->
      <el-table-column type="expand">
        <template slot-scope="props">
          <form-result-item :result="props.row"></form-result-item>
        </template>
      </el-table-column>
      <!-- SUBMITTED AT -->
      <el-table-column prop="SubmittedAt" label="Submitted At" key="SubmittedAt"> </el-table-column>
      <!-- FORM RESULTS -->
      <el-table-column :prop="field" :label="field" v-for="field in resultFieldKeys" :key="field">
        <template slot-scope="props">
          <form-result-value-field :value="props.row[field]" :trim="true" />
        </template>
      </el-table-column>
      <!-- DROPDOWN TOGGLE BUTTON -->
      <el-table-column v-if="showViewButton">
        <template slot-scope="props">
          <el-button size="mini" @click="showItem(props.row.id)">View</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- PAGINATION -->
    <el-pagination
      background
      layout="prev, pager, next, sizes, slot"
      :page-sizes="[10, 20, 50, 100]"
      :total="results.total"
      :current-page="current"
      :page-size="limit"
      @current-change="$emit('currentChange', $event)"
      @size-change="$emit('sizeChange', $event)"
      style="margin: 20px; text-align: right"
    >
      <template>
        <el-button
          size="mini"
          class="extra-btn"
          :disabled="results.total === 0"
          @click="exportResults(false)"
        >
          Export CSV (Current Range)
        </el-button>
        <el-button size="mini" class="extra-btn" @click="exportResults(true)">
          Export CSV (All Dates)
        </el-button>
      </template>
    </el-pagination>
  </div>
</template>

<script>
import _ from "lodash";
import FormResultItem from "@/components/Form/FormResultItem";
import FormResultValueField from "./FormResultValueField.vue";
import { defaultPickerOptions } from "@/helperMethods/util";
import moment from "moment";

export default {
  name: "FormResult",
  props: {
    form: Object,
    results: Object,
    showViewButton: { type: Boolean, default: false },
    current: { type: Number, default: 1 },
    limit: { type: Number, default: 10 },
    searchDate: { type: Array },
    searchKeyword: { type: String, default: "" },
  },
  components: {
    FormResultItem,
    FormResultValueField,
  },
  data() {
    return {
      search: this.searchKeyword,
      searchDateRange: this.searchDate,
      pickerOptions: defaultPickerOptions,
    };
  },
  computed: {
    resultFieldKeys() {
      const properties = this.form.settings.fields.properties;
      const required = this.form.settings.fields.required;
      const resultKeys = _.intersection(Object.keys(properties), required);
      return [...resultKeys];
    },
    resultsData() {
      return (
        this.results?.list?.map((r) => {
          return {
            ID: r.id,
            SESSION: r.sessionId,
            STATE: r.stateId,
            ...r.result,
            SubmittedAt: moment(r.result.SubmittedAt || r.updatedAt).format("lll [GMT]Z"),
          };
        }) || { list: [], total: 0 }
      );
    },
  },
  watch: {
    search: _.debounce(function () {
      this.$emit("searchKeywordChange", this.search);
    }, 500),
  },
  methods: {
    showItem(resultId) {},
    exportResults(isExportAll) {
      const confirmQuestion = isExportAll
        ? "Do you want to export all results?"
        : "Would you like to export the results for the current date range?";
      this.$confirm(confirmQuestion)
        .then(async () => {
          this.$emit("exportResults", isExportAll);
        })
        .catch(() => {});
    },
    expandRow(row) {
      this.$refs.resultTable.toggleRowExpansion(row);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-table__expanded-cell {
    padding: 10px;
  }

  .result {
    border: 1px solid #eee;
    border-collapse: collapse;
    display: inline-block;

    td:first-child {
      width: 2%;
      font-weight: bold;
      white-space: nowrap;
    }

    td {
      padding: 2px 8px !important;
    }
  }

  .extra-btn {
    text-transform: uppercase;
    border: 1px solid #d8dce5;

    &:hover {
      border: 1px solid rgba(228, 57, 43, 0.5);
    }

    span {
      font-size: 10px;
    }
  }

  .search-container {
    padding: 20px;
  }
}
</style>
