import { MutationTree } from "vuex";
import { OutreachState } from "./types";

const mutations: MutationTree<OutreachState> = {
  SET_OUTREACH_CONTACTS(state, payload) {
    const { count = 0, data = [] } = payload;
    state.contacts.data = data;
    state.contacts.count = count;
  },
  SET_OUTREACH_TAGS(state, payload) {
    state.tags.data = payload;
  },
  ADD_OUTREACH_TAG(state, payload) {
    state.tags.data = [...state.tags.data, payload];
  },
  REMOVE_OUTREACH_TAG(state, payload) {
    state.tags.data = [...state.tags.data].filter((tag) => tag.id !== payload);
  },
  UPDATE_OUTREACH_TAG(state, payload) {
    state.tags.data = [...state.tags.data].map((tag) => {
      if (tag.id === payload.id) return { ...tag, ...payload };
      return tag;
    });
  },
  ADD_OUTREACH_STAGE(state, payload) {
    state.stages.data = [...state.stages.data, payload];
  },
  REMOVE_OUTREACH_STAGE(state, payload) {
    state.stages.data = [...state.stages.data].filter((tag) => tag.id !== payload);
  },
  UPDATE_OUTREACH_STAGE(state, payload) {
    state.stages.data = [...state.stages.data].map((tag) => {
      if (tag.id === payload.id) return { ...tag, ...payload };
      return tag;
    });
  },
  SET_OUTREACH_STAGES(state, payload) {
    state.stages.data = payload;
  },
  SET_OUTREACH_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_CONTACT_FILTER_BOARD(state, payload) {
    state.filterBoard = payload;
  },
  SET_CONTACT_SORT_BOARD(state, payload) {
    state.sortBoard = payload;
  },
  SET_CONTACT_ATTRIBUTES(state, payload) {
    state.contactAttributes = payload;
  },
};
export default mutations;
